import { useEffect, useRef, useState } from 'react';
import { Button, Form, Modal, ModalProps, Spinner } from 'react-bootstrap';
import {
  TrashFill,
  PencilFill,
  PersonFill,
  XCircleFill,
  CheckCircleFill,
} from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import {
  useLazyGetClientUsersQuery,
  useLazyGetClientQuery,
  useUpdateClientMutation,
  useUpdateClientAdminsMutation,
} from '../../../../features/client/clientApi.slice';
import { UserRole } from '../../../../types/user.d';
import { IUpdateClientDto } from '../../../../types/client.d';
import { setErrorMessage, setSuccessMessage } from '../../../../features/layout/layout.slice';
import ClientPowerbiSettings from '../../../../components/ClientPowerbiSettings/ClientPowerbiSettings';
import ClientGuardiansSettings from '../../../../components/ClientGuardiansSettings/ClientGuardiansSettings';

interface EditClientModalProps {
  modalProps: ModalProps;
  clientId: string;
  clientName: string;
}

// TODO: Improve form (errors, saving edit, etc...)
function EditClientModal(props: EditClientModalProps) {
  const { clientId, clientName, modalProps } = props;
  const { show, onHide, centered, closeButton } = modalProps;
  const dispatch = useDispatch();

  /* Client Related */
  const [updateClient] = useUpdateClientMutation();
  const clientFormRef = useRef<HTMLFormElement | null>(null);
  const [getClient, { data: client, isFetching }] = useLazyGetClientQuery();
  const [clientUpdate, setClientUpdate] = useState<IUpdateClientDto>({} as IUpdateClientDto);

  /* Client Admins Related */
  const [getClientAdmins] = useLazyGetClientUsersQuery();
  const [updateClientAdmins] = useUpdateClientAdminsMutation();
  const adminUserFormRef = useRef<HTMLFormElement | null>(null);
  const [adminEmails, setAdminEmails] = useState<string[]>([]);
  const [editedAdmin, setEditedAdmin] = useState<string | null>(null); // Admin storing the new email input value
  const [originalEmailEdit, setOriginalEmailEdit] = useState<string | null>(null); // Admin with former email value to differentiate from map list
  const [adminEmailsTemp, setAdminEmailsTemp] = useState<string[]>([]);
  const [addingNewAdmin, setAddingNewAdmin] = useState<boolean>(false);
  const [newAdminEmail, setNewAdminEmail] = useState<string>('');

  const initiateAdminEdit = (email: string): void => {
    setOriginalEmailEdit(email);
    setAdminEmailsTemp(adminEmails.filter((adminEmail) => adminEmail !== email));
    setEditedAdmin(email);
  };

  const deleteAdmin = (email: string): void => {
    setAdminEmails(adminEmails.filter((adminEmail) => adminEmail !== email));
  };

  const saveAdminHandler = (): void => {
    if (editedAdmin) setAdminEmails([...adminEmailsTemp, editedAdmin]);
    else {
      dispatch(setErrorMessage('Please provide an email'));
      return;
    }
    setEditedAdmin(null);
    setOriginalEmailEdit(null);
    setAdminEmailsTemp([]);
  };

  const saveNewAdmin = (): void => {
    if (newAdminEmail) {
      setAdminEmails([...adminEmails, newAdminEmail]);
      setAddingNewAdmin(false);
    }
  };

  const submitUpdateClientAdmins = (): void => {
    if (clientId) {
      updateClientAdmins({ clientId, emailList: adminEmails })
        .unwrap()
        .then(() => dispatch(setSuccessMessage('Admins succesfully updated')))
        .catch((err) => dispatch(setErrorMessage(`Admins update failed: ${err.data.message}`)));
    }
  };

  useEffect(() => {
    if (clientId) {
      getClient(clientId)
        .unwrap()
        .then((c) =>
          setClientUpdate({
            ...clientUpdate,
            clientId: c.id,
            hasPBIEmbedded: c.hasPBIEmbedded,
            hasGuardians: c.hasGuardians,
            primaryYearsFrom: c.primaryYearsFrom,
            primaryYearsTo: c.primaryYearsTo,
            middleYearsFrom: c.middleYearsFrom,
            middleYearsTo: c.middleYearsTo,
            secondaryYearsFrom: c.secondaryYearsFrom,
            secondaryYearsTo: c.secondaryYearsTo,
          })
        )
        .catch(() => dispatch(setErrorMessage('Unable to get client details')));
      getClientAdmins({ clientId, role: UserRole.ADMIN })
        .unwrap()
        .then((clientAdmins) => setAdminEmails(clientAdmins.map(({ email }) => email)));
    }
  }, [clientId]);

  return (
    <Modal id="editClientModal" show={show} onHide={onHide} centered={centered} scrollable>
      <Modal.Header closeButton={closeButton} className="border-0 p-0">
        <Modal.Title className="modal-title">{clientName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isFetching && (
          <div id="modalSpinner">
            <Spinner animation="border" />
          </div>
        )}
        {!isFetching && !!client && (
          <>
            <Form
              id="editClientForm"
              ref={clientFormRef}
              onSubmit={(e) => {
                e.preventDefault();
                updateClient(clientUpdate)
                  .unwrap()
                  .then(() => dispatch(setSuccessMessage('Client updated successfully')))
                  .catch((err) =>
                    dispatch(setErrorMessage(`Client update failed: ${err.data.message}`))
                  );
              }}
              className="edit-client-fields"
            >
              <Form.Text className="form-subtitle">CLIENT DETAILS</Form.Text>
              <hr className="my-2" />
              <ClientPowerbiSettings
                client={client}
                clientUpdate={clientUpdate}
                setClientUpdate={setClientUpdate}
              />
              <ClientGuardiansSettings
                client={client}
                clientUpdate={clientUpdate}
                setClientUpdate={setClientUpdate}
              />
              <div className="form-button">
                <Button type="submit" variant="primary">
                  UPDATE CLIENT
                </Button>
              </div>
            </Form>
            <Form
              id="editAdminUserForm"
              ref={adminUserFormRef}
              onSubmit={(e) => {
                e.preventDefault();
                submitUpdateClientAdmins();
              }}
              className="edit-client-fields"
            >
              <Form.Text className="form-subtitle"> CLIENT ADMIN USERS </Form.Text>
              <hr className="my-2" />
              {adminEmails.map((email) => (
                <Form.Group className="existing-client-admins" key={email}>
                  <Form.Control
                    type="text"
                    defaultValue={email}
                    disabled={originalEmailEdit !== email}
                    onChange={(e) => setEditedAdmin(e.target.value)}
                    className="form-input my-1"
                  />
                  {originalEmailEdit !== email && (
                    <PencilFill
                      type="button"
                      className="client-admin-icon edit-admin-icon"
                      onClick={() => initiateAdminEdit(email)}
                    />
                  )}
                  {originalEmailEdit === email && (
                    <CheckCircleFill
                      type="button"
                      className="client-admin-icon confirm-admin-icon"
                      onClick={() => saveAdminHandler()}
                    />
                  )}
                  {originalEmailEdit !== email && (
                    <TrashFill
                      type="button"
                      className="client-admin-icon delete-admin-icon"
                      onClick={() => deleteAdmin(email)}
                    />
                  )}
                  {originalEmailEdit === email && (
                    <XCircleFill
                      type="button"
                      className="client-admin-icon cancel-admin-icon"
                      onClick={() => setOriginalEmailEdit(null)}
                    />
                  )}
                </Form.Group>
              ))}
              {adminEmails.length === 0 && (
                <p className="client-admin-empty">
                  <i>No Admin Users for this client</i>
                </p>
              )}
              {addingNewAdmin && (
                <Form.Group className="existing-client-admins">
                  <Form.Control
                    type="text"
                    onChange={(e) => setNewAdminEmail(e.target.value)}
                    autoFocus
                  />
                  <CheckCircleFill
                    type="button"
                    className="client-admin-icon confirm-admin-icon form-input my-1"
                    onClick={() => saveNewAdmin()}
                  />
                  <XCircleFill
                    type="button"
                    className="client-admin-icon cancel-admin-icon"
                    onClick={() => {
                      setNewAdminEmail('');
                      setAddingNewAdmin(false);
                    }}
                  />
                </Form.Group>
              )}
              <Button
                className="add-new-client-btn"
                variant="outline-secondary"
                disabled={addingNewAdmin}
                onClick={() => setAddingNewAdmin(true)}
              >
                <PersonFill />
                <span>Add new Admin user</span>
              </Button>
              <div className="form-button">
                <Button type="submit" variant="primary">
                  UPDATE USERS
                </Button>
              </div>
            </Form>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default EditClientModal;

function ServerError() {
  return (
    <div id="serverError">
      <h1>Internal Server Error</h1>
      <h5>Please, come back later to access the platform.</h5>
    </div>
  );
}

export default ServerError;

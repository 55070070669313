import { Button, Modal, ModalProps } from 'react-bootstrap';

export interface IConfirmationProps {
  title: string;
  body: string;
  modalProps: ModalProps;
  handleConfirm: () => void;
}

function Confirmation(props: IConfirmationProps) {
  const { title, body, modalProps, handleConfirm } = props;
  const { show, onHide, centered } = modalProps;

  return (
    <Modal show={show} onHide={onHide} centered={centered} scrollable>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleConfirm}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Confirmation;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface ILoadingAction {
  isPageLoading: boolean;
  loaderMessage: string;
}

export interface ILayout {
  errorMessage: string;
  loaderMessage: string;
  successMessage: string;
  isPageLoading: boolean;
  isSidebarCollapsed: boolean;
  showMobileSidebar: boolean;
}

const initialState: ILayout = {
  errorMessage: '',
  loaderMessage: '',
  successMessage: '',
  isPageLoading: false,
  isSidebarCollapsed: true,
  showMobileSidebar: false,
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
    setSuccessMessage: (state, action: PayloadAction<string>) => {
      state.successMessage = action.payload;
    },
    setIsPageLoading: (state, action: PayloadAction<ILoadingAction>) => {
      state.isPageLoading = action.payload.isPageLoading;
      state.loaderMessage = action.payload.loaderMessage;
    },
    setIsSidebarCollapsed: (state, action: PayloadAction<boolean>) => {
      state.isSidebarCollapsed = action.payload;
    },
    toggleMobileSidebar: (state) => {
      state.showMobileSidebar = !state.showMobileSidebar;
    },
  },
});

export const {
  setErrorMessage,
  setSuccessMessage,
  setIsPageLoading,
  setIsSidebarCollapsed,
  toggleMobileSidebar,
} = layoutSlice.actions;

export function buildLoaderAction(state: boolean, message = ''): ILoadingAction {
  return {
    isPageLoading: state,
    loaderMessage: message,
  };
}

export const selectErrorMessage = (state: RootState) => state.layout.errorMessage;
export const selectLoaderMessage = (state: RootState) => state.layout.loaderMessage;
export const selectIsPageLoading = (state: RootState) => state.layout.isPageLoading;
export const selectSuccessMessage = (state: RootState) => state.layout.successMessage;
export const selectShowMobileSidebar = (state: RootState) => state.layout.showMobileSidebar;
export const selectIsSidebarCollapsed = (state: RootState) => state.layout.isSidebarCollapsed;

export default layoutSlice.reducer;

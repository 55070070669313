import { AuthenticationResult } from '@azure/msal-browser';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

interface IMsGraphTokenState {
  value: AuthenticationResult | null;
}

const initialState: IMsGraphTokenState = {
  value: null,
};

export const msGraphTokenSlice = createSlice({
  name: 'msGraphToken',
  initialState,
  reducers: {
    saveMsGraphToken: (state, action: PayloadAction<AuthenticationResult>) => {
      state.value = action.payload;
    },
  },
});

export const { saveMsGraphToken } = msGraphTokenSlice.actions;

export const selectMsGraphToken = (state: RootState) => state.msGraphToken.value;

export default msGraphTokenSlice.reducer;

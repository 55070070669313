import { IActiveDirectoryGroup, IActiveDirectoryMember } from '../../types/azure.d';
import apiSlice from '../api/api.slice';
import Tags from '../tags';

const azureApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    keepCapacityAlive: builder.mutation<void, void>({
      query: () => ({
        url: '/azure/keepCapacityAlive',
        method: 'POST',
      }),
    }),
    getActiveDirectoryGroups: builder.query<IActiveDirectoryGroup[], string>({
      query: (name) => ({
        url: `/azure/ad/groups?name=${name}`,
        method: 'GET',
      }),
      providesTags: [Tags.AZURE_AD_GROUPS],
    }),
    getActiveDirectoryGroupMembers: builder.query<IActiveDirectoryMember[], string>({
      query: (groupId: string) => ({
        url: `/azure/ad/groups/${groupId}/members`,
        method: 'GET',
      }),
      providesTags: [Tags.AZURE_AD_GROUP_MEMBERS],
    }),
  }),
});

export const {
  useKeepCapacityAliveMutation,
  useGetActiveDirectoryGroupsQuery,
  useLazyGetActiveDirectoryGroupsQuery,
  useLazyGetActiveDirectoryGroupMembersQuery,
} = azureApiSlice;

export default azureApiSlice;

import { ReactComponent as AlphabetA } from '../assets/report-icons/a-alphabet.svg';
import { ReactComponent as AlphabetN } from '../assets/report-icons/n-alphabet.svg';
import { ReactComponent as AlphabetP } from '../assets/report-icons/p-alphabet.svg';
import { ReactComponent as NounBuilding } from '../assets/report-icons/noun-building.svg';
import { ReactComponent as NounCheck } from '../assets/report-icons/noun-check.svg';
import { ReactComponent as NounClient } from '../assets/report-icons/noun-client.svg';
import { ReactComponent as NounDollar } from '../assets/report-icons/noun-dollar.svg';
import { ReactComponent as NounGraduationCap } from '../assets/report-icons/noun-graduation-cap.svg';
import { ReactComponent as NounId } from '../assets/report-icons/noun-id.svg';
import { ReactComponent as NounMan } from '../assets/report-icons/noun-man.svg';
import { ReactComponent as NounManager } from '../assets/report-icons/noun-manager.svg';
import { ReactComponent as NounPerson } from '../assets/report-icons/noun-person.svg';
import { ReactComponent as NounPlant } from '../assets/report-icons/noun-plant.svg';
import { ReactComponent as NounUser } from '../assets/report-icons/noun-user.svg';
import { ReactComponent as NounWoman } from '../assets/report-icons/noun-woman.svg';
import { ReactComponent as NounWrite } from '../assets/report-icons/noun-write.svg';

interface IReport {
  id: string;
  version: number;
  createdAt: string;
  updatedAt: string | null;
  deletedAt: string | null;
  name: string;
  pbiReportId: string;
  datasetId: string;
  webUrl: string;
  embedUrl: string;
  type: string;
  powerbiId: string;
  hasRLS: boolean;
  rlsRoles: string[];
  clientId: string | null;
  displayName: string;
  icon: ReportIcons;
  displayOrder: number;
  sectionId: string | null;
  forGuardians: boolean;
  schoolYearSections: SchoolYearSections[];
}

export interface IReportWithPages extends IReport {
  pages: IPage[];
}

export interface IPage {
  createdAt: string;
  deletedAt: string | null;
  displayName: string;
  id: string;
  name: string;
  order: number;
  reportId: string;
  updatedAt: string | null;
  version: number;
}

export enum ReportIcons {
  ALPHABET_A = 'AlphabetA',
  ALPHABET_N = 'AlphabetN',
  ALPHABET_P = 'AlphabetP',
  NOUN_BUILDING = 'NounBuilding',
  NOUN_CHECK = 'NounCheck',
  NOUN_CLIENT = 'NounClient',
  NOUN_DOLLAR = 'NounDollar',
  NOUN_GRADUATION_CAP = 'NounGraduationCap',
  NOUN_ID = 'NounId',
  NOUN_MAN = 'NounMan',
  NOUN_MANAGER = 'NounManager',
  NOUN_PERSON = 'NounPerson',
  NOUN_PLANT = 'NounPlant',
  NOUN_USER = 'NounUser',
  NOUN_WOMAN = 'NounWoman',
  NOUN_WRITE = 'NounWrite',
}

export const ReportIconsMap = {
  [ReportIcons.ALPHABET_A]: AlphabetA,
  [ReportIcons.ALPHABET_N]: AlphabetN,
  [ReportIcons.ALPHABET_P]: AlphabetP,
  [ReportIcons.NOUN_BUILDING]: NounBuilding,
  [ReportIcons.NOUN_CHECK]: NounCheck,
  [ReportIcons.NOUN_CLIENT]: NounClient,
  [ReportIcons.NOUN_DOLLAR]: NounDollar,
  [ReportIcons.NOUN_GRADUATION_CAP]: NounGraduationCap,
  [ReportIcons.NOUN_ID]: NounId,
  [ReportIcons.NOUN_MAN]: NounMan,
  [ReportIcons.NOUN_MANAGER]: NounManager,
  [ReportIcons.NOUN_PERSON]: NounPerson,
  [ReportIcons.NOUN_PLANT]: NounPlant,
  [ReportIcons.NOUN_USER]: NounUser,
  [ReportIcons.NOUN_WOMAN]: NounWoman,
  [ReportIcons.NOUN_WRITE]: NounWrite,
};

export enum SchoolYearSections {
  PRIMARY = 'Primary',
  MIDDLE = 'Middle',
  SECONDARY = 'Secondary',
}

export default IReport;

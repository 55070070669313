import { useEffect, useState } from 'react';
import { Button, Modal, ModalProps } from 'react-bootstrap';
import SearchBox from '../../SearchBox/SearchBox';

interface IGroupMember {
  id: string;
  email: string;
}

interface IGroupMembersModalProps {
  modalProps: ModalProps;
  members: IGroupMember[];
}

function GroupMembersModal(props: IGroupMembersModalProps) {
  const { members, modalProps } = props;
  const { show, onHide } = modalProps;
  const [selectedMember, setSelectedMember] = useState<string | null>(null);
  const [filteredMembers, setFilteredMembers] = useState<IGroupMember[]>(members);

  const onMemberSearchChange = (input: string) => {
    setFilteredMembers(
      members.filter((member) =>
        member.email.toLowerCase().trim().includes(input.toLowerCase().trim())
      )
    );
  };

  useEffect(() => {
    setSelectedMember(null);
    setFilteredMembers(members);
  }, [members, show]);

  return (
    <Modal id="members-modal" show={show} onHide={onHide} centered>
      <Modal.Header closeButton>GROUP MEMBERS</Modal.Header>
      <Modal.Body>
        {!selectedMember && (
          <SearchBox
            items={filteredMembers ?? []}
            itemDisplayKey="email"
            itemKey="id"
            limit={10}
            searchLabel=""
            onChange={onMemberSearchChange}
            onItemClick={(member) => setSelectedMember(member.email)}
          />
        )}
        {!!selectedMember && (
          <div className="members-selected">
            <span>Member:</span>
            <span>{selectedMember}</span>
          </div>
        )}
        <div className="members-modal-btns">
          <Button
            variant="secondary"
            className="members-modal-btn"
            onClick={() => {
              onMemberSearchChange('');
              setSelectedMember(null);
            }}
            disabled={!selectedMember}
          >
            RESET
          </Button>
          <Button className="members-modal-btn" onClick={onHide}>
            OK
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default GroupMembersModal;

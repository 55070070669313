import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, OverlayTrigger } from 'react-bootstrap';
import Tooltip from '../Tooltip/Tooltip';
import SpinLoader from '../SpinLoader/SpinLoader';
import { setErrorMessage, setSuccessMessage } from '../../features/layout/layout.slice';
import { useSyncClientMutation } from '../../features/client/clientApi.slice';
import { useSyncWorkspaceMutation } from '../../features/powerbi/powerbiApi.slice';

export enum SyncType {
  CLIENT = 'Client',
  WORKSPACE = 'Workspace',
}

interface SyncButtonProps {
  id: string;
  type: SyncType;
  tooltip: string;
}

function SyncButton({ tooltip, type, id }: SyncButtonProps) {
  const dispatch = useDispatch();
  const [syncClientGroups] = useSyncClientMutation();
  const [syncWorkspace] = useSyncWorkspaceMutation();
  const [isSyncing, setIsSyncing] = useState<boolean>(false);

  const onSync = async () => {
    if (!id) return;
    let syncFunction;
    setIsSyncing(true);
    if (type === SyncType.CLIENT) syncFunction = syncClientGroups;
    else syncFunction = syncWorkspace;
    await syncFunction(id)
      .unwrap()
      .then(() => dispatch(setSuccessMessage(`${type} successfully synced`)))
      .catch((err) => dispatch(setErrorMessage(`${type} sync failed: ${err.data.message}`)))
      .finally(() => setIsSyncing(false));
  };

  return (
    <OverlayTrigger
      placement="right"
      overlay={
        <div style={{ zIndex: '99999' }}>
          <Tooltip>{tooltip}</Tooltip>
        </div>
      }
    >
      <Button id={isSyncing ? 'activeSyncButton' : 'syncButton'} onClick={onSync}>
        <SpinLoader isSpinning={isSyncing} size={26} />
      </Button>
    </OverlayTrigger>
  );
}

export default SyncButton;

import { useAppSelector } from '../../features/hooks';
import SignOutButton from '../../components/Buttons/SignOutButton';
import { selectCurrentUser } from '../../features/user/user.slice';

function UnregisteredUser() {
  const currentUser = useAppSelector(selectCurrentUser);
  const clientName = window.location.host.split('.')[0].toUpperCase();

  return (
    <div id="unregisteredUser">
      <h1>
        {currentUser ? `The user ${currentUser.email} does` : 'You do'} not have access to{' '}
        {clientName}
      </h1>
      <h5>Please, make sure you have been added to the platform.</h5>
      <div className="sign-out">
        <SignOutButton className="unauthorized-signout" variant="secondary">
          LOG OUT
        </SignOutButton>
        <p>Sign out from Microsoft</p>
      </div>
    </div>
  );
}

export default UnregisteredUser;

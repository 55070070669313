import { AuthenticationResult, IPublicClientApplication } from '@azure/msal-browser';
import { msGraphRequest, redirectRequest } from '../config/authConfig';

export const azureSigninPopup = (
  instance: IPublicClientApplication
): Promise<AuthenticationResult> => {
  return instance.loginPopup(msGraphRequest);
};

export const azureSigninRedirect = async (instance: IPublicClientApplication): Promise<void> => {
  return instance.loginRedirect(redirectRequest);
};

export const azureSignout = (instance: IPublicClientApplication): Promise<void> => {
  return instance.logoutPopup();
};

export const acquireGraphToken = (
  instance: IPublicClientApplication
): Promise<AuthenticationResult> => {
  return instance.acquireTokenSilent({
    scopes: msGraphRequest.scopes,
    account: instance.getAllAccounts()[0],
  });
};

export const acquireServiceToken = (
  instance: IPublicClientApplication,
  clientId: string,
  tenantId: string
): Promise<AuthenticationResult> => {
  return instance.acquireTokenSilent({
    scopes: [`api://${clientId}/Service.Access`],
    account: instance.getAllAccounts()[0],
    authority: `https://login.microsoftonline.com/${tenantId}`,
  });
};

export const buildHeaders = (accessToken: string) => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', `Bearer ${accessToken}`);
  return headers;
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { SessionType } from '../../types/user.d';

export interface IAuthData {
  isAuthenticating: boolean;
  sessionType: SessionType;
}

const initialState: IAuthData = {
  isAuthenticating: false,
  sessionType: (localStorage.getItem('SessionType') as SessionType) || SessionType.STAFF,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsAuthenticating: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticating = action.payload;
    },
    setSessionType: (state, action: PayloadAction<SessionType>) => {
      state.sessionType = action.payload;
    },
  },
});

export const { setIsAuthenticating, setSessionType } = authSlice.actions;

export const selectIsAuthenticating = (state: RootState) => state.auth.isAuthenticating;
export const selectSessionType = (state: RootState) => state.auth.sessionType;
export const selectIsGuardianSession = (state: RootState) =>
  state.auth.sessionType === SessionType.GUARDIAN;

export default authSlice.reducer;

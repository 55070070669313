import { Button } from 'react-bootstrap';
import { UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import logo from '../../assets/edubi-logo.png';
import { useAppDispatch } from '../../features/hooks';
import { azureSigninRedirect } from '../../utils/session.util';
import { setSessionType } from '../../features/auth/auth.slice';
import { buildLoaderAction, setIsPageLoading } from '../../features/layout/layout.slice';
import { SessionType } from '../../types/user.d';

function Signin(): JSX.Element {
  const { instance } = useMsal();
  const dispatch = useAppDispatch();

  const handleSignInRedirect = (sessionType: SessionType) => {
    localStorage.setItem('SessionType', sessionType);
    dispatch(setSessionType(sessionType));
    dispatch(setIsPageLoading(buildLoaderAction(true)));
    azureSigninRedirect(instance);
  };

  return (
    <UnauthenticatedTemplate>
      <div id="signinPage">
        <div className="signin-container">
          <img className="signin-image" src={logo} alt="EduBI logo" />
          <Button
            className="signin-button"
            variant="primary"
            onClick={() => handleSignInRedirect(SessionType.STAFF)}
          >
            Staff Login
          </Button>
          <Button
            className="signin-button"
            variant="primary"
            onClick={() => handleSignInRedirect(SessionType.GUARDIAN)}
          >
            Guardian Login
          </Button>
        </div>
      </div>
    </UnauthenticatedTemplate>
  );
}

export default Signin;

import React, { FunctionComponent, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Icon } from 'react-bootstrap-icons';
import { Button, OverlayTrigger } from 'react-bootstrap';
import Tooltip from '../Tooltip/Tooltip';
import { useAppSelector } from '../../features/hooks';
import { selectShowMobileSidebar, toggleMobileSidebar } from '../../features/layout/layout.slice';

export enum NavType {
  MAIN = 'main',
}

export interface INavButtonProps {
  linkTo: string;
  children: React.ReactNode;
  navType?: NavType;
  BsIcon: Icon | FunctionComponent;
  isActive: boolean;
}

function NavButton(props: INavButtonProps): JSX.Element {
  const dispatch = useDispatch();
  const [show, setShow] = useState<boolean>(false);
  const { linkTo, children, navType, BsIcon, isActive } = props;
  const showMobileSidebar = useAppSelector(selectShowMobileSidebar);

  const onClickNavButton = () => {
    if (!showMobileSidebar) return;
    dispatch(toggleMobileSidebar());
  };

  return (
    <Link className="link" to={linkTo}>
      <OverlayTrigger
        placement="right"
        overlay={
          <div>
            <Tooltip>{children}</Tooltip>
          </div>
        }
        show={show}
        onToggle={(nextShow) => setShow(nextShow)}
      >
        <Button
          onClick={onClickNavButton}
          className={`nav-button type-${navType}`}
          active={isActive}
        >
          <BsIcon className="nav-button-icon" />
          <span className="nav-button-text">{children}</span>
        </Button>
      </OverlayTrigger>
    </Link>
  );
}

NavButton.defaultProps = {
  navType: NavType.MAIN,
};

export default NavButton;

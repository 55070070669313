import React from 'react';
import {
  PersonFillGear,
  Clipboard2DataFill,
  DatabaseFillGear,
  BuildingFillGear,
  PersonFillUp,
} from 'react-bootstrap-icons';
import { Container, Tab, Tabs } from 'react-bootstrap';
import EditClients from './EditClients/EditClients';
import ReportSettings from './ReportSettings/ReportSettings';
import WorkspaceSettings from './WorkspaceSettings/WorkspaceSettings';
import ClientsResources from './ClientsResources/ClientsResources';
import GlobalAdmin from './GlobalAdmin/GlobalAdmin';

function Admin() {
  return (
    <div id="adminPage">
      <Container>
        <Tabs defaultActiveKey="clientsConfiguration">
          <Tab
            eventKey="clientsConfiguration"
            title={
              <div className="tab-title">
                <PersonFillGear size={18} />
                <div className="admin-tab-name">Clients Configuration</div>
              </div>
            }
          >
            <EditClients />
          </Tab>
          <Tab
            eventKey="clientsResources"
            title={
              <div className="tab-title">
                <DatabaseFillGear size={18} />
                <div className="admin-tab-name">Clients Resources</div>
              </div>
            }
          >
            <ClientsResources />
          </Tab>
          <Tab
            eventKey="workspaces"
            title={
              <div className="tab-title">
                <BuildingFillGear size={18} />
                <div className="admin-tab-name">Workspaces</div>
              </div>
            }
          >
            <WorkspaceSettings />
          </Tab>
          <Tab
            eventKey="reports"
            title={
              <div className="tab-title">
                <Clipboard2DataFill size={18} />
                <div className="admin-tab-name">Reports</div>
              </div>
            }
          >
            <ReportSettings />
          </Tab>
          <Tab
            eventKey="globalAdmins"
            title={
              <div className="tab-title">
                <PersonFillUp size={18} />
                <div className="admin-tab-name">Global Admins</div>
              </div>
            }
          >
            <GlobalAdmin />
          </Tab>
        </Tabs>
      </Container>
    </div>
  );
}

export default Admin;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

interface IReportState {
  isUserIdle: boolean;
  currentPbiReportId: string | null | undefined;
  currentReportPageName: string | null | undefined;
}

const initialState: IReportState = {
  isUserIdle: false,
  currentPbiReportId: null,
  currentReportPageName: null,
};

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    /* User will be idle if there is no interaction with the report ReportPage after a certain period of time */
    setIsUserIdle: (state, action: PayloadAction<boolean>) => {
      state.isUserIdle = action.payload;
    },
    /* Active report in sidebar */
    setCurrentPbiReportId: (state, action: PayloadAction<string | null | undefined>) => {
      state.currentPbiReportId = action.payload;
    },
    /* Report page selected in sidebar */
    setCurrentReportPageName: (state, action: PayloadAction<string | null | undefined>) => {
      state.currentReportPageName = action.payload;
    },
  },
});

export const { setIsUserIdle, setCurrentPbiReportId, setCurrentReportPageName } =
  reportSlice.actions;

export const selectIsUserIdle = (state: RootState) => state.report.isUserIdle;
export const selectCurrentPbiReportId = (state: RootState) => state.report.currentPbiReportId;
export const selectCurrentReportPageName = (state: RootState) => state.report.currentReportPageName;

export default reportSlice.reducer;

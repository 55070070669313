export enum Tags {
  USER = 'User',
  USERS = 'Users',
  GROUPS = 'Groups',
  POWERBI = 'Powerbi',
  CLIENT = 'Client',
  AZURE_AD_GROUPS = 'Azure AD Groups',
  AZURE_AD_GROUP_MEMBERS = 'Azure AD Group Members',
  WORKSPACE_REPORTS = 'Workspace Reports',
  CLIENT_USERS = 'Client Users',
  REPORTS = 'Reports',
  SECTIONS = 'Sections',
}

export const tags: Tags[] = [
  Tags.USER,
  Tags.USERS,
  Tags.GROUPS,
  Tags.POWERBI,
  Tags.CLIENT,
  Tags.AZURE_AD_GROUPS,
  Tags.AZURE_AD_GROUP_MEMBERS,
  Tags.WORKSPACE_REPORTS,
  Tags.CLIENT_USERS,
  Tags.REPORTS,
  Tags.SECTIONS,
];

export default Tags;

import React from 'react';
import { useMsal } from '@azure/msal-react';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { azureSignout } from '../../utils/session.util';
import { useSignoutMutation } from '../../features/auth/authApi.slice';
import { useAppDispatch } from '../../features/hooks';
import { buildLoaderAction, setIsPageLoading } from '../../features/layout/layout.slice';

interface ISignOutButtonProps {
  className: string;
  children: React.ReactNode;
  variant: string;
}

function SignOutButton(props: ISignOutButtonProps): JSX.Element {
  const { className, children, variant } = props;
  const { instance } = useMsal();
  const navigate = useNavigate();
  const [serviceSignout] = useSignoutMutation();
  const dispatch = useAppDispatch();

  const handleSignOut = () => {
    dispatch(setIsPageLoading(buildLoaderAction(true)));
    document.body.classList.add('not-auth');
    document.body.classList.remove('is-auth');
    serviceSignout();
    azureSignout(instance).finally(() => {
      navigate('/signin');
      dispatch(setIsPageLoading(buildLoaderAction(false)));
    });
  };

  return (
    <Button className={className} variant={variant} onClick={() => handleSignOut()}>
      {children}
    </Button>
  );
}

export default SignOutButton;

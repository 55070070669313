import IGroup, { ICreateGroup, IGroupWithReports } from '../../types/group.d';
import apiSlice from '../api/api.slice';
import Tags from '../tags';

interface IUpdateGroup {
  groupId: string;
  name: string;
}

interface IUpdateGroupReport {
  action: 'add' | 'remove';
  groupId: string;
  reportId: string;
}

const groupApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addGroup: builder.mutation<IGroup, ICreateGroup>({
      query: (args: ICreateGroup) => ({
        url: `/groups`,
        method: 'POST',
        body: args,
      }),
      invalidatesTags: [Tags.GROUPS, Tags.CLIENT],
    }),
    deleteGroup: builder.mutation<IGroup, string>({
      query: (groupId: string) => ({
        url: `/groups/${groupId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [Tags.GROUPS, Tags.USER],
    }),
    updateGroup: builder.mutation<IGroup, IUpdateGroup>({
      query: (args: IUpdateGroup) => ({
        url: `/groups/${args.groupId}`,
        method: 'PUT',
        body: {
          name: args.name,
        },
      }),
      invalidatesTags: [Tags.GROUPS],
    }),
    updateGroupReport: builder.mutation<IGroupWithReports, IUpdateGroupReport>({
      query: (args: IUpdateGroupReport) => ({
        url: `/groups/${args.groupId}/reports/${args.reportId}/${args.action}`,
        method: 'PUT',
      }),
      invalidatesTags: [Tags.GROUPS],
    }),
  }),
});

export const {
  useAddGroupMutation,
  useUpdateGroupMutation,
  useUpdateGroupReportMutation,
  useDeleteGroupMutation,
} = groupApiSlice;

export default groupApiSlice;

import React from 'react';

function NotFound(): JSX.Element {
  return (
    <div id="not-found-page">
      <h1>Page not found</h1>
    </div>
  );
}

export default NotFound;

import IPowerbi, {
  ICreatePowerbiDto,
  IPowerbiReports,
  IUpdatePowerbiDto,
} from '../../types/powerbi.d';
import apiSlice from '../api/api.slice';
import Tags from '../tags';

interface IGetReportEmbedArgs {
  powerbiId: string;
  pbiReportId: string;
}

const powerbiApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // TODO: Update response type
    getReportEmbed: builder.query<any, IGetReportEmbedArgs>({
      query: (args: IGetReportEmbedArgs) => ({
        url: `/powerbi/${args.powerbiId}/api/reports/${args.pbiReportId}/embed`,
        method: 'GET',
      }),
    }),
    refreshEmbedToken: builder.query<void, string>({
      query: (powerbiId: string) => ({
        url: `/powerbi/${powerbiId}/api/embed-token`,
        method: 'GET',
      }),
    }),
    getAllPowerbi: builder.query<IPowerbiReports[], boolean>({
      query: (includeReports: boolean) => ({
        url: `/powerbi?reports=${includeReports}`,
        method: 'GET',
      }),
      providesTags: [Tags.WORKSPACE_REPORTS],
    }),
    createPowerbi: builder.mutation<IPowerbi, ICreatePowerbiDto>({
      query: (args: ICreatePowerbiDto) => ({
        url: `/powerbi`,
        method: 'POST',
        body: args,
      }),
      invalidatesTags: [Tags.POWERBI, Tags.WORKSPACE_REPORTS],
    }),
    updatePowerbi: builder.mutation<void, IUpdatePowerbiDto>({
      query: (args: IUpdatePowerbiDto) => ({
        url: `/powerbi/${args.id}`,
        method: 'PUT',
        body: {
          workspaceId: args.workspaceId,
          workspaceName: args.workspaceName,
          capacityTimeout: args.capacityTimeout,
        },
      }),
      invalidatesTags: [Tags.POWERBI, Tags.WORKSPACE_REPORTS],
    }),
    deletePowerbi: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `/powerbi/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [Tags.POWERBI, Tags.WORKSPACE_REPORTS],
    }),
    syncWorkspace: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `/powerbi/${id}/sync`,
        method: 'GET',
      }),
      invalidatesTags: [Tags.POWERBI, Tags.WORKSPACE_REPORTS],
    }),
  }),
});

export const {
  useLazyGetReportEmbedQuery,
  useLazyRefreshEmbedTokenQuery,
  useGetAllPowerbiQuery,
  useCreatePowerbiMutation,
  useUpdatePowerbiMutation,
  useSyncWorkspaceMutation,
  useDeletePowerbiMutation,
} = powerbiApiSlice;

export default powerbiApiSlice;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Form, ModalProps } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Search, ShieldCheck } from 'react-bootstrap-icons';
import { IGroupReportsUsers } from '../../../types/group.d';
import { useUpdateGroupReportMutation } from '../../../features/group/groupApi.slice';
import { setErrorMessage, setSuccessMessage } from '../../../features/layout/layout.slice';
import IReport from '../../../types/report.d';
import { useGetClientReportsQuery } from '../../../features/report/reportApi.slice';
import GroupMembersModal from '../GroupMembersModal/GroupMembersModal';

interface IEditGroupFormProps {
  selectedGroup: IGroupReportsUsers;
  clientId: string;
  readOnly: boolean;
}

function EditGroup(props: IEditGroupFormProps): JSX.Element {
  const { selectedGroup, clientId, readOnly } = props;
  const dispatch = useDispatch();
  const [updateGroupReport] = useUpdateGroupReportMutation();

  /* Component states */
  const [showMembers, setShowMembers] = useState<boolean>(false);
  const { data: powerbiReports } = useGetClientReportsQuery(clientId);

  /* Ref to access form element */
  const groupFormRef = useRef<HTMLFormElement | null>(null);

  const groupReportChange = (selectedReport: IReport, add: boolean) => {
    updateGroupReport({
      groupId: selectedGroup.id,
      reportId: selectedReport.id,
      action: add ? 'add' : 'remove',
    })
      .unwrap()
      .then(() => {
        dispatch(setSuccessMessage(`Report successfully ${add ? 'added' : 'removed'}`));
      })
      .catch((error) => {
        dispatch(setErrorMessage(error.message));
      });
  };

  // TODO: ED-169
  /* --------------- Scroll handling section --------------- */
  const [scrollableBox1, setScrollableBox1] = useState<HTMLDivElement | null>(null);

  const handleScroll = (e: any) => {
    const elem = e?.target;
    if (!elem) return;
    if (elem.scrollTop + elem.clientHeight >= elem.scrollHeight) {
      // TODO: do something
    } else if (elem.scrollTop === 0) {
      // TODO: do something
    } else {
      // TODO: do something
    }
  };

  const divRef1 = useCallback(
    (node: HTMLDivElement) => {
      setScrollableBox1(node);
    },
    [selectedGroup]
  );

  const groupMembersProps: ModalProps = {
    show: showMembers,
    onHide: () => setShowMembers(false),
  };

  useEffect(() => {
    scrollableBox1?.addEventListener('scroll', handleScroll);
    return () => {
      scrollableBox1?.removeEventListener('scroll', handleScroll);
    };
  }, [scrollableBox1]);

  /* --------------- TEMPLATE --------------- */
  return (
    <div className="edit-group-container">
      <div className="edit-header">
        <p className="edit-header-title">Group name</p>
        <p className="edit-header-value">{selectedGroup.name}</p>
      </div>
      <div className="edit-header">
        <p className="edit-header-title">Group description</p>
        <p className="edit-header-value">{selectedGroup.description}</p>
      </div>
      <div className="edit-header">
        <p className="edit-header-title">Group members</p>
        <Button
          variant="secondary"
          className="edit-header-members"
          onClick={() => setShowMembers(true)}
        >
          <Search />
          <span>Check members</span>
        </Button>
      </div>
      <Form ref={groupFormRef} id="editGroupForm">
        <div className="manage-reports">
          <Form.Group className="mt-4">
            <Form.Label className="title">
              <p>Enabled Reports</p>
            </Form.Label>
            <div className={`select-options ${selectedGroup.id}`} ref={divRef1}>
              {powerbiReports?.map((report) => {
                const checked = !!selectedGroup?.reports?.find(
                  (groupReport) => groupReport.id === report.id
                );
                return (
                  <Form.Check
                    type="switch"
                    checked={checked}
                    onChange={(event) => groupReportChange(report, event.target.checked)}
                    label={
                      <div className={`report-option-name ${checked ? 'enabled' : ''}`}>
                        {report.hasRLS && <ShieldCheck size={18} className="report-rls-icon" />}
                        {report.displayName}
                      </div>
                    }
                    key={report.id}
                    disabled={readOnly}
                  />
                );
              })}
            </div>
          </Form.Group>
        </div>
      </Form>
      <GroupMembersModal
        members={selectedGroup.users.map(({ id, email }) => ({ id, email }))}
        modalProps={groupMembersProps}
      />
    </div>
  );
}

export default EditGroup;

import { ArrowRepeat } from 'react-bootstrap-icons';

interface ISpinLoaderProps {
  isSpinning: boolean;
  size: number;
}

function SpinLoader({ isSpinning, size }: ISpinLoaderProps) {
  return <ArrowRepeat size={size} id={isSpinning ? 'spinLoader' : ''} />;
}

export default SpinLoader;

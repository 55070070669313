import { AuthenticationResult } from '@azure/msal-browser';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

interface IEdubiServiceTokenState {
  value: AuthenticationResult | null;
}

const initialState: IEdubiServiceTokenState = {
  value: null,
};

export const edubiServiceTokenSlice = createSlice({
  name: 'edubiServiceToken',
  initialState,
  reducers: {
    saveEdubiServiceToken: (state, action: PayloadAction<AuthenticationResult>) => {
      state.value = action.payload;
    },
  },
});

export const { saveEdubiServiceToken } = edubiServiceTokenSlice.actions;

export const selectEdubiServiceToken = (state: RootState) => state.edubiServiceToken.value;

export default edubiServiceTokenSlice.reducer;

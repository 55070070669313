import React from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';

export enum ToastType {
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
}

export interface IToastProps {
  message: string;
  type: ToastType;
  show: boolean;
  onClose: () => void;
  sidebarState: 'expanded' | 'collapsed' | 'hidden';
}

function BsToast(props: IToastProps): JSX.Element {
  const { message, type, show, onClose, sidebarState } = props;

  return (
    <div className={`notification-toast sidebar-state-${sidebarState}`}>
      <ToastContainer position="bottom-center">
        <Toast bg={type} onClose={() => onClose()} show={show} autohide>
          <Toast.Header>
            <span className="toast-message">{message}</span>
          </Toast.Header>
        </Toast>
      </ToastContainer>
    </div>
  );
}

export default BsToast;

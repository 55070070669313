import { Embed, models } from 'powerbi-client';
import React, { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../../features/hooks';
import { selectCurrentReportPageName } from '../../../features/report/report.slice';
import Loader from '../../Loader/Loader';

export interface IPBIEmbedProps {
  visualType: string;
  id: string | undefined;
  embedUrl: string | undefined;
  embedToken: string | undefined;
  retryTimeoutId: React.MutableRefObject<NodeJS.Timer | null>;
  idleUserTimeoutId: React.MutableRefObject<NodeJS.Timer | null>;
  keepCapacityOnInterval: React.MutableRefObject<NodeJS.Timer | null>;
  handleShowMobileMenu: () => void;
  startIdleUserTimeout: () => void;
  startKeepCapacityAliveInterval: () => void;
}

/**
 * When the capacity is started by the backend, te backend returns the embed info before the capacity finished to start.
 * In this case, the page is reloaded to attempt again until the capacity finished to start and loads the model.
 * */
const CAPACITY_ERROR_MESSAGES = [
  'Capacity operation failed with error code NotFound',
  'Capacity operation failed with error code CapacityNotActive',
];
const REPORT_LOADING_INITIAL_MESSAGE = 'We are preparing the report for you';
const REPORT_LONG_LOADING_MESSAGE = 'This operation might take a few seconds...';

const MAX_MOBILE_WIDTH = 480;

export function PBIEmbed(props: IPBIEmbedProps): JSX.Element {
  const {
    visualType,
    id,
    embedUrl,
    embedToken,
    retryTimeoutId,
    idleUserTimeoutId,
    keepCapacityOnInterval,
    handleShowMobileMenu,
    startIdleUserTimeout,
    startKeepCapacityAliveInterval,
  } = props;
  const [reportLoadingMessage, setReportLoadingMessage] = useState<string>(
    REPORT_LOADING_INITIAL_MESSAGE
  );
  const [isEmbedLoading, setIsEmbedLoading] = useState<boolean>(true);
  const [areParamsLoading, setAreParamsLoading] = useState<boolean>(true);
  const [report, setReport] = useState<Embed | null>(null);
  const embedContainerRef = useRef<HTMLDivElement>(null);
  const pageName = useAppSelector(selectCurrentReportPageName);

  const getVisualConfig = (): models.IReportEmbedConfiguration => ({
    type: visualType,
    id,
    embedUrl,
    pageName: undefined,
    accessToken: embedToken,
    tokenType: models.TokenType.Embed,
    settings: {
      panes: {
        pageNavigation: {
          visible: false,
        },
      },
      layoutType:
        window.innerWidth <= MAX_MOBILE_WIDTH
          ? models.LayoutType.MobilePortrait
          : models.LayoutType.Master,
    },
  });

  const handleScreenResize = () => {
    if (!embedContainerRef.current) return;
    setReport(window.powerbi.embed(embedContainerRef.current, getVisualConfig()));
  };

  const onReportError = (event: any) => {
    const reason: string | null = event?.detail?.technicalDetails?.errorInfo
      ? (event?.detail?.technicalDetails?.errorInfo[0]?.value as string)
      : null;
    if (reason && CAPACITY_ERROR_MESSAGES.includes(reason) && embedContainerRef.current) {
      setIsEmbedLoading(true);
      setReportLoadingMessage(REPORT_LONG_LOADING_MESSAGE);
      window.powerbi.reset(embedContainerRef.current); // Reset container to avoid displaying error message to user
      setReport(window.powerbi.embed(embedContainerRef.current, getVisualConfig())); // Embed again the report in the container
    } else {
      setIsEmbedLoading(false);
      // TODO: Log the reason of error
    }
  };

  const onReportRendered = () => {
    setIsEmbedLoading(false);
    /* Start or reset idle user timeout */
    if (idleUserTimeoutId.current) clearTimeout(idleUserTimeoutId.current);
    startIdleUserTimeout();
    if (!keepCapacityOnInterval.current) startKeepCapacityAliveInterval();
    window.addEventListener('resize', handleScreenResize);
  };

  const onEmbedComponentUnmount = () => {
    window.removeEventListener('resize', handleScreenResize);
    if (keepCapacityOnInterval.current) clearInterval(keepCapacityOnInterval.current);
    if (retryTimeoutId.current) clearTimeout(retryTimeoutId.current);
    if (idleUserTimeoutId.current) clearTimeout(idleUserTimeoutId.current);
    setReportLoadingMessage(REPORT_LOADING_INITIAL_MESSAGE);
  };

  /* Check if params are loading */
  useEffect(() => {
    if (!id || !embedToken || !embedUrl || !pageName) {
      setAreParamsLoading(true);
    } else {
      setAreParamsLoading(false);
      if (embedContainerRef.current)
        setReport(window.powerbi.embed(embedContainerRef.current, getVisualConfig()));
    }
  }, [id, embedToken, embedUrl, pageName]);

  /* Add embed event handlers */
  useEffect(() => {
    if (report) {
      report.on('error', onReportError);
      report.on('rendered', onReportRendered);
      report.on('swipeEnd', handleShowMobileMenu);
    }

    return () => {
      if (report) {
        report.off('error');
        report.off('rendered');
        report.off('swipeEnd');
      }
    };
  }, [report]);

  /* Tasks to run before component unmount */
  useEffect(() => () => onEmbedComponentUnmount());

  return (
    <div
      id="embedPage"
      className={!isEmbedLoading && !areParamsLoading ? 'is-not-loading' : 'is-loading'}
    >
      <div id="embedLoader">
        <Loader message={reportLoadingMessage} className="embed-loader" />
      </div>
      <div id="embedComponent">
        <div className={`${visualType}-style-class`} ref={embedContainerRef} />
      </div>
    </div>
  );
}

import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ExclamationTriangleFill, List } from 'react-bootstrap-icons';
import IdleModal from './IdleModal/IdleModal';
import {
  selectIsUserIdle,
  setCurrentPbiReportId,
  setCurrentReportPageName,
  setIsUserIdle,
} from '../../features/report/report.slice';
import {
  useLazyGetReportEmbedQuery,
  useLazyRefreshEmbedTokenQuery,
} from '../../features/powerbi/powerbiApi.slice';
import { PBIEmbed } from '../../components/Powerbi/PBIEmbed/PBIEmbed';
import { useAppDispatch, useAppSelector } from '../../features/hooks';
import { selectCurrentUserClient } from '../../features/user/user.slice';
import { useKeepCapacityAliveMutation } from '../../features/azure/azureApi.slice';
import { setErrorMessage, toggleMobileSidebar } from '../../features/layout/layout.slice';

/* Time to wait until attempting again to get embed params */
const RETRY_TIME_TO_GET_EMBED_REPORT = 5_000;

const { REACT_APP_KEEP_CAPACITY_ALIVE_INTERVAL_TIME } = process.env;
const KEEP_CAPACITY_ALIVE_INTERVAL_TIME = REACT_APP_KEEP_CAPACITY_ALIVE_INTERVAL_TIME || 30_000;

function Report(): JSX.Element {
  /* Report embed section */
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isUserIdle = useAppSelector(selectIsUserIdle);
  const [getReportEmbed] = useLazyGetReportEmbedQuery();
  const client = useAppSelector(selectCurrentUserClient);
  const { pbiReportId, powerbiId, pageName } = useParams();
  const [embedParams, setEmbedParams] = useState<any>(null);
  const [keepCapacityAlive] = useKeepCapacityAliveMutation();
  const [refreshEmbedToken] = useLazyRefreshEmbedTokenQuery();
  const retryTimeoutId = useRef<NodeJS.Timer | null>(null);
  const idleUserTimeoutId = useRef<NodeJS.Timer | null>(null);
  const keepCapacityOnInterval = useRef<NodeJS.Timer | null>(null);
  const showMobileMenuTimeoutId = useRef<NodeJS.Timer | null>(null);
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
  const IDLE_USER_TIMEOUT = client?.powerbi.capacityTimeout || 240_000;

  const [isEmbedError, setIsEmbedError] = useState<boolean>(false);

  const getEmbedReport = async (_pbiReportId: string, _powerbiId: string) => {
    getReportEmbed({
      powerbiId: _powerbiId,
      pbiReportId: _pbiReportId,
    })
      .unwrap()
      .then((params) => {
        setEmbedParams(params);
      })
      .catch((error) => {
        if (error?.status === 503) {
          /* Power BI Capacity is on idle state. Wait X seconds and retry. */
          if (retryTimeoutId.current) clearTimeout(retryTimeoutId.current);
          retryTimeoutId.current = setTimeout(() => {
            if (powerbiId && pbiReportId) getEmbedReport(pbiReportId, powerbiId);
          }, RETRY_TIME_TO_GET_EMBED_REPORT);
        } else if (error?.status === 400) {
          setIsEmbedError(true);
          dispatch(setErrorMessage('Error. We are unable to retrieve this page'));
        } else {
          setIsEmbedError(true);
          dispatch(setErrorMessage(JSON.stringify(error)));
        }
      });
  };

  const startIdleUserTimeout = (): void => {
    const timeoutId = setTimeout(() => {
      dispatch(setIsUserIdle(true));
      if (keepCapacityOnInterval.current) clearInterval(keepCapacityOnInterval.current);
    }, +IDLE_USER_TIMEOUT);
    idleUserTimeoutId.current = timeoutId;
  };

  const startKeepCapacityAliveInterval = () => {
    const intervalId = setInterval(() => {
      keepCapacityAlive();
    }, +KEEP_CAPACITY_ALIVE_INTERVAL_TIME);
    keepCapacityOnInterval.current = intervalId;
  };

  useEffect(() => {
    if (powerbiId && pbiReportId) getEmbedReport(pbiReportId, powerbiId);
    dispatch(setCurrentPbiReportId(pbiReportId));
    dispatch(setCurrentReportPageName(pageName));
  }, [powerbiId, pbiReportId]);

  /* Idle timeout section */
  // TODO: add mechanism to enable and disable this feature

  const onContinueSelected = async () => {
    if (!powerbiId) return;
    await refreshEmbedToken(powerbiId);
    startIdleUserTimeout();
    startKeepCapacityAliveInterval();
    dispatch(setIsUserIdle(false));
  };

  const onLeavePageSelected = () => {
    navigate('/');
    dispatch(setIsUserIdle(false));
  };

  const onToggleMobileSidebar = () => dispatch(toggleMobileSidebar());

  const handleShowMobileMenu = () => {
    setShowMobileMenu(true);
    const timeoutId = setTimeout(() => {
      setShowMobileMenu(false);
    }, 5000);
    showMobileMenuTimeoutId.current = timeoutId;
  };

  useEffect(() => {
    return () => {
      if (idleUserTimeoutId.current) clearTimeout(idleUserTimeoutId.current);
      if (keepCapacityOnInterval.current) clearInterval(keepCapacityOnInterval.current);
      if (showMobileMenuTimeoutId.current) clearTimeout(showMobileMenuTimeoutId.current);
    };
  }, []);

  return (
    <div id="reportPage">
      <button
        type="button"
        id="mobile-hamburger"
        className={showMobileMenu ? 'menu' : 'no-menu'}
        onClick={onToggleMobileSidebar}
      >
        <List size={24} />
      </button>
      <IdleModal
        show={isUserIdle}
        onContinueSelected={onContinueSelected}
        onLeavePageSelected={onLeavePageSelected}
      />
      <div className="reportEmbed">
        {isEmbedError ? (
          <div className="embed-error-message">
            <ExclamationTriangleFill />
            <span>Something went wrong. Please contact support for help.</span>
          </div>
        ) : (
          <PBIEmbed
            visualType="report"
            id={embedParams?.details?.id}
            embedUrl={embedParams?.details?.embedUrl}
            embedToken={embedParams?.embedToken?.token}
            retryTimeoutId={retryTimeoutId}
            idleUserTimeoutId={idleUserTimeoutId}
            keepCapacityOnInterval={keepCapacityOnInterval}
            handleShowMobileMenu={handleShowMobileMenu}
            startIdleUserTimeout={startIdleUserTimeout}
            startKeepCapacityAliveInterval={startKeepCapacityAliveInterval}
          />
        )}
      </div>
    </div>
  );
}

export default Report;

import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/react';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { tags } from '../tags';

const BASE_URL = process.env.REACT_APP_SERVICE_BASE_URL;

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  credentials: 'include',
});

const baseQueryWithReauth: BaseQueryFn = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result?.error) {
    // TODO: handle refresh token
  }

  return result;
};

const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  tagTypes: [...tags],
  refetchOnMountOrArgChange: true,
  endpoints: () => ({}),
});

export default apiSlice;

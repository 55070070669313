import React from 'react';
import { Form } from 'react-bootstrap';
import { SchoolYearSections } from '../../types/report.d';
import IClient, { IUpdateClientDto } from '../../types/client.d';

interface ClientGuardiansSettingsProps {
  client: IClient;
  clientUpdate: IUpdateClientDto;
  setClientUpdate: React.Dispatch<React.SetStateAction<IUpdateClientDto>>;
}

function ClientGuardiansSettings({
  client,
  clientUpdate,
  setClientUpdate,
}: ClientGuardiansSettingsProps) {
  const onToggleGuardians = (event: React.ChangeEvent<HTMLInputElement>) => {
    setClientUpdate({
      ...clientUpdate,
      guardiansGradeFrom: 1,
      guardiansGradeTo: 12,
      hasGuardians: event.target.checked,
    });
  };

  /**
   * Given a school section (primary, middle, secondary) it returns the available years for the section.
   * @param schoolSection Enum value (primary, middle or secondary)
   * Given the school section it uses the min and max school years the client has (guardiansGradeFrom/guardiansGradeTo) as well as the min and max set for other sections to return only the available options.
   * E.g. Given secondary section, for min it would first check middle's max, if it's null then it would check primary's max and if it's null it defaults to clients guardiansGradeFrom.
   * It checks the expected closest limits until it has the min/max for the section and returns the options array built using those limits.
   * @returns Array of available years (numbers) for the school section passed as param
   */
  const getAvailableSchoolYears = (schoolSection: SchoolYearSections): number[] => {
    if (!client) return [];
    let min = 1;
    let max = -1;
    if (schoolSection === SchoolYearSections.PRIMARY) {
      min = client.guardiansGradeFrom;
      max +=
        clientUpdate.middleYearsFrom ??
        clientUpdate.secondaryYearsFrom ??
        client.guardiansGradeTo + 1;
    } else if (schoolSection === SchoolYearSections.MIDDLE) {
      min += clientUpdate.primaryYearsTo ?? client.guardiansGradeFrom - 1;
      max += clientUpdate.secondaryYearsFrom ?? client.guardiansGradeTo + 1;
    } else {
      min +=
        clientUpdate.middleYearsTo ?? clientUpdate.primaryYearsTo ?? client.guardiansGradeFrom - 1;
      max = client.guardiansGradeTo;
    }
    return Array.from(Array(max - min + 1).keys()).map((k) => k + min);
  };

  const onToggleSchoolSection = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const years = getAvailableSchoolYears(target.name as SchoolYearSections);
    setClientUpdate({
      ...clientUpdate,
      [`${target.name.toLocaleLowerCase()}YearsFrom`]: target.checked ? years[0] : null,
      [`${target.name.toLocaleLowerCase()}YearsTo`]: target.checked
        ? years[years.length - 1]
        : null,
    });
  };

  return (
    <>
      <div className="client-guardians-switch">
        <Form.Label className="client-guardians-label">Has guardians</Form.Label>
        <Form.Switch defaultChecked={client.hasGuardians} onChange={onToggleGuardians} />
      </div>
      {clientUpdate?.hasGuardians && (
        <>
          <div className="client-guardians-group">
            <Form.Label className="client-guardians-label">School Grade Years From</Form.Label>
            <Form.Select
              className="guardian-year-select"
              defaultValue={client.guardiansGradeFrom ?? ''}
              onChange={(e) =>
                setClientUpdate({ ...clientUpdate, guardiansGradeFrom: +e.target.value })
              }
            >
              {Array.from(Array(12).keys()).map((i) => (
                <option key={`primary-min-${i}`}>{i + 1}</option>
              ))}
            </Form.Select>
          </div>
          <div className="client-guardians-group">
            <Form.Label className="client-guardians-label">School Grade Years To</Form.Label>
            <Form.Select
              className="guardian-year-select"
              defaultValue={client.guardiansGradeTo ?? ''}
              onChange={(e) =>
                setClientUpdate({ ...clientUpdate, guardiansGradeTo: +e.target.value })
              }
            >
              {Array.from(Array(12).keys()).map((i) => (
                <option key={`primary-min-${i}`}>{i + 1}</option>
              ))}
            </Form.Select>
          </div>
          <div className="client-guardians-group" id="guardian-year-selects">
            <div className="client-guardians-section">
              <div className="year-select-title">
                <Form.Label className="client-guardians-label">Primary Years</Form.Label>
                <Form.Switch
                  name={SchoolYearSections.PRIMARY}
                  onChange={onToggleSchoolSection}
                  defaultChecked={!!clientUpdate.primaryYearsFrom && !!clientUpdate.primaryYearsTo}
                />
              </div>
              {!!clientUpdate.primaryYearsFrom && !!clientUpdate.primaryYearsTo && (
                <div className="d-flex align-items-center">
                  <Form.Select
                    defaultValue={clientUpdate.primaryYearsFrom}
                    className="guardian-year-select"
                    onChange={(e) =>
                      setClientUpdate({
                        ...clientUpdate,
                        primaryYearsFrom: +e.target.value,
                      })
                    }
                  >
                    {getAvailableSchoolYears(SchoolYearSections.PRIMARY).map((i) => (
                      <option key={`primary-min-${i}`}>{i}</option>
                    ))}
                  </Form.Select>
                  <Form.Text className="mx-2"> to </Form.Text>
                  <Form.Select
                    defaultValue={clientUpdate.primaryYearsTo}
                    className="guardian-year-select"
                    onChange={(e) =>
                      setClientUpdate({ ...clientUpdate, primaryYearsTo: +e.target.value })
                    }
                  >
                    {getAvailableSchoolYears(SchoolYearSections.PRIMARY).map((i) => (
                      <option key={`primary-max-${i}`}>{i}</option>
                    ))}
                  </Form.Select>
                </div>
              )}
            </div>
            <div className="client-guardians-section">
              <div className="year-select-title">
                <Form.Label className="client-guardians-label">Middle Years</Form.Label>
                <Form.Switch
                  name={SchoolYearSections.MIDDLE}
                  onChange={onToggleSchoolSection}
                  defaultChecked={!!clientUpdate.middleYearsFrom && !!clientUpdate.middleYearsTo}
                />
              </div>
              {!!clientUpdate.middleYearsFrom && !!clientUpdate.middleYearsTo && (
                <div className="d-flex align-items-center">
                  <Form.Select
                    defaultValue={clientUpdate.middleYearsFrom}
                    className="guardian-year-select"
                    onChange={(e) =>
                      setClientUpdate({ ...clientUpdate, middleYearsFrom: +e.target.value })
                    }
                  >
                    {getAvailableSchoolYears(SchoolYearSections.MIDDLE).map((i) => (
                      <option key={`middle-min-${i}`}>{i}</option>
                    ))}
                  </Form.Select>
                  <Form.Text className="mx-2"> to </Form.Text>
                  <Form.Select
                    defaultValue={clientUpdate.middleYearsTo}
                    className="guardian-year-select"
                    onChange={(e) =>
                      setClientUpdate({ ...clientUpdate, middleYearsTo: +e.target.value })
                    }
                  >
                    {getAvailableSchoolYears(SchoolYearSections.MIDDLE).map((i) => (
                      <option key={`middle-max-${i}`}>{i}</option>
                    ))}
                  </Form.Select>
                </div>
              )}
            </div>
            <div className="client-guardians-section">
              <div className="year-select-title">
                <Form.Label className="client-guardians-label">Secondary Years</Form.Label>
                <Form.Switch
                  name={SchoolYearSections.SECONDARY}
                  onChange={onToggleSchoolSection}
                  defaultChecked={
                    !!clientUpdate.secondaryYearsFrom && !!clientUpdate.secondaryYearsTo
                  }
                />
              </div>
              {!!clientUpdate.secondaryYearsFrom && !!clientUpdate.secondaryYearsTo && (
                <div className="d-flex align-items-center">
                  <Form.Select
                    defaultValue={clientUpdate.secondaryYearsFrom}
                    className="guardian-year-select"
                    onChange={(e) =>
                      setClientUpdate({
                        ...clientUpdate,
                        secondaryYearsFrom: +e.target.value,
                      })
                    }
                  >
                    {getAvailableSchoolYears(SchoolYearSections.SECONDARY).map((i) => (
                      <option key={`secondary-min-${i}`}>{i}</option>
                    ))}
                  </Form.Select>
                  <Form.Text className="mx-2"> to </Form.Text>
                  <Form.Select
                    defaultValue={clientUpdate.secondaryYearsTo}
                    className="guardian-year-select"
                    onChange={(e) =>
                      setClientUpdate({
                        ...clientUpdate,
                        secondaryYearsTo: +e.target.value,
                      })
                    }
                  >
                    {getAvailableSchoolYears(SchoolYearSections.SECONDARY).map((i) => (
                      <option key={`secondary-max-${i}`}>{i}</option>
                    ))}
                  </Form.Select>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ClientGuardiansSettings;

import Tags from '../tags';
import apiSlice from '../api/api.slice';
import { SessionType } from '../../types/user.d';

interface ISigninDto {
  token: string;
  sessionType: SessionType;
}

interface ISigninResponseDto {
  sessionType: SessionType;
}

const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    signin: builder.mutation<ISigninResponseDto, ISigninDto>({
      query: ({ token, sessionType }) => ({
        url: '/auth/signin',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: { sessionType },
      }),
      invalidatesTags: [Tags.USER],
    }),
    signout: builder.mutation<any, void>({
      query: () => ({
        url: '/auth/signout',
        method: 'POST',
      }),
      invalidatesTags: [Tags.USER],
    }),
  }),
});

export const { useSigninMutation, useSignoutMutation } = authApiSlice;

export default authApiSlice;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { IGroupReportsUsers } from '../../types/group.d';
import IUser from '../../types/user.d';

interface IGroupState {
  currentSettingsTab: 'groups' | 'users' | 'reports';
  editingGroup: IGroupReportsUsers | null;
  editingUser: IUser | null;
}

const initialState: IGroupState = {
  currentSettingsTab: 'groups',
  editingGroup: null,
  editingUser: null,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setCurrentSettingsTab: (state, action: PayloadAction<'groups' | 'users' | 'reports'>) => {
      state.currentSettingsTab = action.payload;
    },
    setEditingGroup: (state, action: PayloadAction<IGroupReportsUsers | null>) => {
      state.editingGroup = action.payload;
    },
    setEditingUser: (state, action: PayloadAction<IUser | null>) => {
      state.editingUser = action.payload;
    },
  },
});

export const { setEditingGroup, setCurrentSettingsTab, setEditingUser } = settingsSlice.actions;

export const selectCurrentSettingsTab = (state: RootState) => state.settings.currentSettingsTab;
export const selectEditingGroup = (state: RootState) => state.settings.editingGroup;
export const selectEditingUser = (state: RootState) => state.settings.editingUser;

export default settingsSlice.reducer;

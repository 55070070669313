import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import {
  useGetAllUsersQuery,
  useSyncGlobalAdminsMutation,
} from '../../../features/user/userApi.slice';
import { UserRole } from '../../../types/user.d';
import { setErrorMessage, setSuccessMessage } from '../../../features/layout/layout.slice';
import SpinLoader from '../../../components/SpinLoader/SpinLoader';

function GlobalAdmin() {
  const dispatch = useDispatch();
  const [syncGlobalAdmins] = useSyncGlobalAdminsMutation();
  const [isSyncing, setIsSyncing] = useState<boolean>(false);
  const { data: users } = useGetAllUsersQuery(UserRole.GLOBAL_ADMIN);

  const onSyncGlobalAdmins = () => {
    setIsSyncing(true);
    syncGlobalAdmins()
      .unwrap()
      .then(() => dispatch(setSuccessMessage('Global Admins successfully synced')))
      .catch((err) => dispatch(setErrorMessage(`Global Admins sync failed, ${err.data?.message}`)))
      .finally(() => setIsSyncing(false));
  };

  return (
    <div id="globalAdmin">
      <span className="ga-title">Global Admins</span>
      {users?.map((user) => (
        <div className="user" key={user.id}>
          <div>
            <span className="user-role">GA</span>
            <span className="user-email">{user.email}</span>
          </div>
        </div>
      ))}
      <Button className="sync-btn" onClick={onSyncGlobalAdmins} disabled={isSyncing}>
        {isSyncing ? <SpinLoader isSpinning={isSyncing} size={20} /> : 'SYNC'}
      </Button>
    </div>
  );
}

export default GlobalAdmin;

import React, { useState } from 'react';
import { Button, ListGroup, ModalProps } from 'react-bootstrap';
import { Person } from 'react-bootstrap-icons';
import IClient from '../../../types/client.d';
import EditClientModal from './EditClientModal/EditClientModal';
import {
  useDeleteClientMutation,
  useGetClientsQuery,
} from '../../../features/client/clientApi.slice';
import AddNewClientModal from './AddNewClientModal/AddNewClientModal';
import Confirmation from '../../../components/Confirmation/Confirmation';

function EditClients() {
  const { data: clients } = useGetClientsQuery();
  const [deleteClient] = useDeleteClientMutation();
  const [clientToEdit, setClientToEdit] = useState<IClient | null>(null);
  const [clientToDelete, setClientToDelete] = useState<IClient | null>(null);
  const [showNewClientModal, setShowNewClientModal] = useState<boolean>(false);
  const newClientModalProps: ModalProps = {
    show: showNewClientModal,
    onHide: () => setShowNewClientModal(false),
    centered: true,
    closeButton: true,
  };
  const editClientModalProps: ModalProps = {
    show: !!clientToEdit,
    onHide: () => setClientToEdit(null),
    centered: true,
    closeButton: true,
  };
  const deleteClientModalProps: ModalProps = {
    show: !!clientToDelete,
    onHide: () => setClientToDelete(null),
    centered: true,
  };
  return (
    <div id="editClients">
      <span className="clients-title">Clients</span>
      <ListGroup horizontal>
        {clients?.map((client) => (
          <ListGroup.Item key={client.id} className="client-item">
            <Person size={65} color="lightgrey" className="client-icon rounded-circle p-1" />
            <div className="m-3 d-flex flex-column justify-content-center align-items-center">
              <span className="client-name"> {client.name} </span>
              <span className="client-code"> #{client.code} </span>
            </div>
            <Button className="client-buttons" onClick={() => setClientToEdit(client)}>
              EDIT CLIENT
            </Button>
            <Button
              variant="secondary"
              className="client-buttons"
              onClick={() => setClientToDelete(client)}
            >
              DELETE CLIENT
            </Button>
          </ListGroup.Item>
        ))}
      </ListGroup>
      {clientToEdit && (
        <EditClientModal
          modalProps={editClientModalProps}
          clientId={clientToEdit.id}
          clientName={clientToEdit.name}
        />
      )}
      <Button
        className="new-client-btn"
        variant="primary"
        onClick={() => setShowNewClientModal(true)}
      >
        NEW CLIENT
      </Button>
      <AddNewClientModal
        modalProps={newClientModalProps}
        handleClose={() => setShowNewClientModal(false)}
      />
      <Confirmation
        modalProps={deleteClientModalProps}
        title={`Delete client ${clientToDelete?.name}`}
        body="Are you sure you want to delete? The information can't be recovered."
        handleConfirm={() => {
          if (!clientToDelete) return;
          deleteClient(clientToDelete.id);
          setClientToDelete(null);
        }}
      />
    </div>
  );
}

export default EditClients;

import React, { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Button, Form, ModalProps, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { CloudCheck, Search } from 'react-bootstrap-icons';
import { ICreateGroup } from '../../../types/group.d';
import { useAddGroupMutation } from '../../../features/group/groupApi.slice';
import { setErrorMessage, setSuccessMessage } from '../../../features/layout/layout.slice';
import IReport from '../../../types/report.d';
import { IActiveDirectoryGroup, IActiveDirectoryMember } from '../../../types/azure.d';
import { useGetClientReportsQuery } from '../../../features/report/reportApi.slice';
import GroupMembersModal from '../GroupMembersModal/GroupMembersModal';

interface IADGroup {
  clientId: string;
  adGroup: IActiveDirectoryGroup;
  adGroupMembers: IActiveDirectoryMember[] | null;
  onCancel: () => void;
}

function ADGroup(props: IADGroup): JSX.Element {
  const { clientId, adGroup, onCancel, adGroupMembers } = props;
  const dispatch = useDispatch();
  const [addNewGroup] = useAddGroupMutation();

  /* Component states */
  const { data: powerbiReports } = useGetClientReportsQuery(clientId);
  const [showMembers, setShowMembers] = useState<boolean>(false);
  const [isGroupSaving, setIsGroupSaving] = useState<boolean>(false);

  const newGroupTemplate: ICreateGroup = {
    activeDirectoryId: adGroup.id,
    name: adGroup.displayName,
    description: adGroup.description,
    clientId,
    reports: [],
  };
  const [newGroup, setNewGroup] = useState<ICreateGroup>(newGroupTemplate);

  useEffect(() => {
    const g: ICreateGroup = {
      activeDirectoryId: adGroup.id,
      name: adGroup.displayName,
      description: adGroup.description,
      clientId,
      reports: [],
    };
    setNewGroup(g);
  }, [adGroup, clientId]);

  /* Ref to access form element */
  const groupFormRef = useRef<HTMLFormElement | null>(null);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const reportIds: string[] = [];
    newGroup.reports?.forEach((report) => reportIds.push(report.id));
    if (clientId) {
      setIsGroupSaving(true);
      addNewGroup(newGroup)
        .unwrap()
        .then(() => {
          dispatch(setSuccessMessage('New group successfully created'));
          if (groupFormRef?.current) groupFormRef.current.reset();
          setNewGroup(newGroupTemplate);
        })
        .catch((error) => {
          dispatch(setErrorMessage(error.data?.message?.[0] ?? 'Unable to create group'));
        })
        .finally(() => {
          setIsGroupSaving(false);
        });
    } else {
      dispatch(setErrorMessage('Something went wrong'));
    }
  };

  /**
   * Handles check/uncheck in report options
   * @param selectedReport report to be added or removed
   * @param add if true, adds the report to the array. If false, removes the report from the array
   */
  const groupReportChange = (selectedReport: IReport, add: boolean) => {
    setNewGroup((prevState) => {
      if (!prevState || !prevState?.reports) return newGroupTemplate;
      if (add && prevState.reports.find((report) => report.id === selectedReport.id))
        return { ...prevState };
      const reports = add
        ? [...prevState.reports, selectedReport]
        : prevState.reports.filter((report) => report.id !== selectedReport.id);
      return { ...prevState, reports };
    });
  };

  // TODO: ED-169
  /* --------------- Scroll handling section --------------- */
  const [scrollableBox1, setScrollableBox1] = useState<HTMLDivElement | null>(null);

  const handleScroll = (e: any) => {
    const elem = e?.target;
    if (!elem) return;
    if (elem.scrollTop + elem.clientHeight >= elem.scrollHeight) {
      // TODO: do something
    } else if (elem.scrollTop === 0) {
      // TODO: do something
    } else {
      // TODO: do something
    }
  };

  const divRef1 = useCallback((node: HTMLDivElement) => {
    setScrollableBox1(node);
  }, []);

  const groupMembersProps: ModalProps = {
    show: showMembers,
    onHide: () => setShowMembers(false),
  };

  useEffect(() => {
    scrollableBox1?.addEventListener('scroll', handleScroll);
    return () => {
      scrollableBox1?.removeEventListener('scroll', handleScroll);
    };
  }, [scrollableBox1]);

  /* --------------- TEMPLATE --------------- */
  return (
    <div className="create-group-container">
      <div className="edit-header">
        <p className="edit-header-title">Group name</p>
        <p className="edit-header-value">{adGroup.displayName}</p>
      </div>
      <div className="edit-header">
        <p className="edit-header-title">Group description</p>
        <p className="edit-header-value">{adGroup.description}</p>
      </div>
      <div className="edit-header">
        <p className="edit-header-title">Group members</p>
        <Button
          variant="secondary"
          className="edit-header-members"
          onClick={() => setShowMembers(true)}
        >
          <Search />
          <span>Check members</span>
        </Button>
      </div>
      <Form ref={groupFormRef} onSubmit={handleSubmit} id="adGroupForm">
        <div className="manage-reports">
          <Form.Group className="mt-4">
            <Form.Label className="title">
              <h5>Select reports</h5>
            </Form.Label>
            <div className="select-options" ref={divRef1}>
              {powerbiReports?.map((report) => {
                return (
                  <Form.Check
                    type="switch"
                    checked={
                      !!newGroup?.reports?.find((groupReport) => groupReport.id === report.id)
                    }
                    onChange={(event) => groupReportChange(report, event.target.checked)}
                    label={report.displayName}
                    key={report.id}
                  />
                );
              })}
            </div>
          </Form.Group>
        </div>
        <div className="form-buttons">
          <Button disabled={newGroup.name === ''} type="submit" className="save-btn">
            {isGroupSaving ? (
              <Spinner className="btn-icon" animation="border" size="sm" />
            ) : (
              <CloudCheck className="btn-icon" />
            )}
            <span>Save</span>
          </Button>
          <Button className="cancel-btn" type="button" variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </Form>
      <GroupMembersModal
        members={adGroupMembers?.map(({ id, mail }) => ({ id, email: mail })) ?? []}
        modalProps={groupMembersProps}
      />
    </div>
  );
}

export default ADGroup;

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, ModalProps } from 'react-bootstrap';
import { ChevronRight, Trash } from 'react-bootstrap-icons';
import { setErrorMessage, setSuccessMessage } from '../../../features/layout/layout.slice';
import IPowerbi, { IUpdatePowerbiDto } from '../../../types/powerbi.d';
import {
  useCreatePowerbiMutation,
  useDeletePowerbiMutation,
  useGetAllPowerbiQuery,
  useUpdatePowerbiMutation,
} from '../../../features/powerbi/powerbiApi.slice';
import {
  useGetClientsQuery,
  useUpdateClientMutation,
} from '../../../features/client/clientApi.slice';
import SearchBox from '../../../components/SearchBox/SearchBox';
import IClient from '../../../types/client.d';
import Confirmation from '../../../components/Confirmation/Confirmation';
import SyncButton, { SyncType } from '../../../components/SyncButton/SyncButton';

function WorkspaceSettings() {
  const dispatch = useDispatch();
  const [updateClient] = useUpdateClientMutation();
  const [createPowerbi] = useCreatePowerbiMutation();
  const [updatePowerbi] = useUpdatePowerbiMutation();
  const [deletePowerbi] = useDeletePowerbiMutation();
  const { data: clients } = useGetClientsQuery();
  const { data: powerbis } = useGetAllPowerbiQuery(true);
  const [clientSearch, setClientSearch] = useState<IClient[]>([]);
  const [editingPowerbi, setEditingPowerbi] = useState<IPowerbi>();
  const [isDeletingPowerbi, setIsDeletingPowerbi] = useState<boolean>(false);

  const deletePowerbiModalProps: ModalProps = {
    show: isDeletingPowerbi,
    onHide: () => setIsDeletingPowerbi(false),
    centered: true,
  };

  const handleClientSearch = (query: string) => {
    if (!query) setClientSearch(clients ?? []);
    else setClientSearch(clientSearch.filter((c) => c.name.includes(query)));
  };

  const handleWorkspaceClientUpdate = (client: IClient, powerbiId: string | null) => {
    updateClient({ clientId: client.id, powerbiId })
      .then(() => dispatch(setSuccessMessage(`${client.name} ${powerbiId ? 'added' : 'removed'}`)))
      .catch(() =>
        dispatch(setErrorMessage(`Error while ${powerbiId ? 'adding' : 'removing'} ${client.name}`))
      );
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!editingPowerbi) return;
    let powerbiFunction;
    let message: string;
    const powerbiDto: IUpdatePowerbiDto = {
      workspaceId: editingPowerbi.workspaceId,
      workspaceName: editingPowerbi.workspaceName,
      capacityTimeout: editingPowerbi.capacityTimeout,
    };
    if (editingPowerbi.id) {
      powerbiFunction = updatePowerbi;
      message = 'Workspace succesfully updated';
      powerbiDto.id = editingPowerbi.id;
    } else {
      powerbiFunction = createPowerbi;
      message = 'Workspace succesfully created';
    }
    powerbiFunction(powerbiDto)
      .unwrap()
      .then(() => dispatch(setSuccessMessage(message)))
      .catch((err) => {
        dispatch(
          setErrorMessage(
            `Workspace ${powerbiDto.id ? 'update' : 'creation'} failed: ${err.data.message}`
          )
        );
      });
  };

  useEffect(() => {
    if (powerbis?.length) {
      if (editingPowerbi) {
        setEditingPowerbi(powerbis.find((powerbi) => powerbi.id === editingPowerbi.id));
      } else {
        setEditingPowerbi(powerbis[0]);
      }
    }
  }, [powerbis]);

  useEffect(() => {
    setClientSearch(clients?.filter((client) => !client.powerbiId) ?? []);
  }, [clients]);

  return (
    <div id="workspaceSettings">
      <span className="workspaces-title">Workspaces</span>
      <div className="workspace-content">
        <div className="left-container">
          {powerbis?.map((powerbi) => (
            <Button
              className="workspace-select-btn"
              key={powerbi.id}
              onClick={() => setEditingPowerbi(powerbi)}
              active={editingPowerbi?.id === powerbi.id}
            >
              <span className="workspace-select-btn-name">{powerbi.workspaceName}</span>
              <ChevronRight />
            </Button>
          ))}
          <Button
            onClick={() => setEditingPowerbi({ capacityTimeout: 1800000 } as IPowerbi)}
            className="workspace-new-btn"
          >
            New workspace
          </Button>
        </div>
        <div className="right-container">
          {editingPowerbi && (
            <form
              key={editingPowerbi?.id}
              className="edit-workspace-form"
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              <div className="sync-btn-container">
                <SyncButton
                  tooltip="Sync the workspace with the latest PowerBI changes"
                  type={SyncType.WORKSPACE}
                  id={editingPowerbi.id}
                />
                {editingPowerbi.lastSync && (
                  <span className="last-sync">
                    Last sync: {new Date(editingPowerbi.lastSync).toString()}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="name" className="edit-workspace-label">
                  Workspace name
                  <input
                    name="name"
                    type="text"
                    className="edit-workspace-input"
                    value={editingPowerbi.workspaceName}
                    onChange={({ target }) =>
                      setEditingPowerbi({ ...editingPowerbi, workspaceName: target.value })
                    }
                    required
                    placeholder="Workspace name"
                    maxLength={32}
                  />
                </label>
              </div>
              <div className="form-group">
                <label htmlFor="id" className="edit-workspace-label">
                  Workspace ID
                  <input
                    name="id"
                    type="text"
                    className="edit-workspace-input"
                    value={editingPowerbi.workspaceId}
                    onChange={({ target }) =>
                      setEditingPowerbi({ ...editingPowerbi, workspaceId: target.value })
                    }
                    required
                    placeholder="Workspace ID"
                  />
                </label>
              </div>
              <div className="form-group">
                <label htmlFor="timeout" className="edit-workspace-label">
                  Capacity timeout(ms)
                  <input
                    name="timeout"
                    type="text"
                    className="edit-workspace-input"
                    value={editingPowerbi.capacityTimeout}
                    onChange={({ target }) =>
                      setEditingPowerbi({ ...editingPowerbi, capacityTimeout: +target.value })
                    }
                    required
                    placeholder="Capacity timeout"
                  />
                </label>
              </div>
              <div className="form-clients-list">
                <span id="clients-label" className="edit-workspace-label">
                  Clients
                </span>
                {!editingPowerbi.clients?.length && (
                  <div className="no-clients"> This workspace has no clients yet! </div>
                )}
                <ul className="workspace-clients">
                  {editingPowerbi.clients?.map((client) => (
                    <li className="workspace-client" key={client.id}>
                      <span className="workspace-client-name">
                        {client.name} - {client.code}
                      </span>
                      <Button
                        className="remove-client-btn"
                        onClick={() => handleWorkspaceClientUpdate(client, null)}
                      >
                        <Trash />
                      </Button>
                    </li>
                  ))}
                </ul>
              </div>
              {editingPowerbi?.id && (
                <div className="search-container">
                  <span className="edit-workspace-label">Add client</span>
                  <SearchBox
                    items={
                      clientSearch.filter(
                        (client) => !editingPowerbi.clients?.find((c) => c.id === client.id)
                      ) ?? []
                    }
                    itemDisplayKey="name"
                    itemKey="id"
                    limit={10}
                    onChange={handleClientSearch}
                    onItemClick={(client) => handleWorkspaceClientUpdate(client, editingPowerbi.id)}
                    searchLabel=""
                  />
                </div>
              )}
              <div className="form-buttons">
                {!!editingPowerbi.id && (
                  <Button
                    className="edit-workspace-btn"
                    onClick={() => setIsDeletingPowerbi(true)}
                    variant="secondary"
                  >
                    DELETE
                  </Button>
                )}
                <Button className="edit-workspace-btn" type="submit">
                  SAVE
                </Button>
              </div>
            </form>
          )}
        </div>
      </div>
      <Confirmation
        title="Delete Workspace"
        body={`Are you sure you want to delete ${editingPowerbi?.workspaceName}? This can't be undone`}
        handleConfirm={() => {
          if (!editingPowerbi) return;
          deletePowerbi(editingPowerbi.id);
          setIsDeletingPowerbi(false);
        }}
        modalProps={deletePowerbiModalProps}
      />
    </div>
  );
}

export default WorkspaceSettings;

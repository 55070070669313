import Loader from '../../components/Loader/Loader';

function Redirect(): JSX.Element {
  return (
    <div id="redirectPage">
      <Loader message="Loading your analytics" className="redirect-loader" />
    </div>
  );
}

export default Redirect;

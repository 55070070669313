import React, { useEffect, useState } from 'react';
import IUser from '../../types/user.d';
import EditUser from './EditUser/EditUser';
import { useAppDispatch, useAppSelector } from '../../features/hooks';
import { selectEditingUser, setEditingUser } from '../../features/settings/settings.slice';
import SearchBox from '../SearchBox/SearchBox';

interface IUserSettingsProps {
  clientUsers: IUser[] | null | undefined;
  readOnly: boolean;
}

function UserSettings(props: IUserSettingsProps): JSX.Element {
  const { clientUsers, readOnly } = props;

  const dispatch = useAppDispatch();
  const editingUser = useAppSelector(selectEditingUser);
  const [filteredUsers, setFilteredUsers] = useState<IUser[]>();

  const onUserSearchChange = (input: string) =>
    setFilteredUsers(
      clientUsers?.filter((user) =>
        user.email.toLowerCase().trim().includes(input.toLowerCase().trim())
      )
    );

  /* Set the selected user to the first user in the list */
  useEffect(() => {
    if (clientUsers && clientUsers?.length > 0) {
      setFilteredUsers(clientUsers);
      if (editingUser)
        dispatch(
          setEditingUser(clientUsers.find((u) => u.id === editingUser.id) || clientUsers[0])
        );
      else dispatch(setEditingUser(clientUsers[0]));
    }
  }, [clientUsers]);

  /* Select user button handler */
  const selectUserBtnHandler = (user?: IUser) =>
    dispatch(setEditingUser(user || (clientUsers?.length ? clientUsers[0] : null)));

  return (
    <div id="userSettings">
      <span className="user-title">Users</span>
      <div className="user-content">
        <div className="left-container">
          <SearchBox
            items={filteredUsers ?? []}
            itemKey="id"
            itemDisplayKey="email"
            limit={10}
            searchLabel=""
            onChange={onUserSearchChange}
            onItemClick={selectUserBtnHandler}
          />
        </div>
        <div className="right-container">
          {editingUser && <EditUser selectedUser={editingUser} readOnly={readOnly} />}
        </div>
      </div>
    </div>
  );
}

export default UserSettings;

import { useDispatch } from 'react-redux';
import { List } from 'react-bootstrap-icons';
import logo from '../../assets/edubi-logo.png';
import SignOutButton from '../Buttons/SignOutButton';
import { toggleMobileSidebar } from '../../features/layout/layout.slice';

function Topbar(): JSX.Element {
  const dispatch = useDispatch();

  const onToggleMobileSidebar = () => dispatch(toggleMobileSidebar());

  return (
    <div id="topbar">
      <img src={logo} alt="EduBI logo" height={56} width={148} />
      <SignOutButton className="topbar-signout" variant="secondary">
        LOG OUT
      </SignOutButton>
      <button type="button" className="topbar-mobile-hamburger" onClick={onToggleMobileSidebar}>
        <List size={40} />
      </button>
    </div>
  );
}

export default Topbar;

import SignOutButton from '../../components/Buttons/SignOutButton';

function ExpiredSession() {
  return (
    <div id="expiredSession">
      <h1>Your session has expired</h1>
      <h5>Please, sign in again to access the platform.</h5>
      <div className="sign-out">
        <SignOutButton className="expired-signout" variant="secondary">
          LOG OUT
        </SignOutButton>
        <p>Sign out from Microsoft</p>
      </div>
    </div>
  );
}

export default ExpiredSession;

import Tags from '../tags';
import apiSlice from '../api/api.slice';
import { ISection, ISectionReports } from '../../types/section.d';

export interface ICreateSectionDto {
  name: string;
  clientId: string;
}

export interface IUpdateSectionDto {
  id: string;
  name: string;
  displayOrder: string;
}

export interface IUpdateSectionOrderDto {
  id: string;
  displayOrder: number;
}

const sectionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getClientSections: builder.query<ISectionReports[], string>({
      query: (clientId: string) => ({
        url: `/sections?clientId=${clientId}`,
        method: 'GET',
      }),
      providesTags: [Tags.SECTIONS],
    }),
    createSection: builder.mutation<ISection, ICreateSectionDto>({
      query: (args) => ({
        url: '/sections',
        method: 'POST',
        body: { name: args.name, clientId: args.clientId },
      }),
      invalidatesTags: [Tags.SECTIONS],
    }),
    updateSection: builder.mutation<null, IUpdateSectionDto>({
      query: (args) => ({
        url: `/sections/${args.id}`,
        method: 'PUT',
        body: { name: args.name, displayOrder: args.displayOrder },
      }),
      invalidatesTags: [Tags.SECTIONS],
    }),
    updateSectionOrder: builder.mutation<void, IUpdateSectionOrderDto[]>({
      query: (order: IUpdateSectionOrderDto[]) => ({
        url: '/sections/display-order',
        method: 'PATCH',
        body: { order },
      }),
      invalidatesTags: [Tags.SECTIONS],
    }),
    deleteSection: builder.mutation<null, string>({
      query: (id: string) => ({
        url: `/sections/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [Tags.SECTIONS],
    }),
  }),
});

export const {
  useGetClientSectionsQuery,
  useCreateSectionMutation,
  useUpdateSectionMutation,
  useDeleteSectionMutation,
  useUpdateSectionOrderMutation,
} = sectionApiSlice;

export default sectionApiSlice;

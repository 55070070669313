import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Accordion, Button, Form } from 'react-bootstrap';
import { ChevronRight, Trash, Save, ExclamationTriangleFill } from 'react-bootstrap-icons';
import { useUpdateReportMutation } from '../../../features/report/reportApi.slice';
import IReport, { SchoolYearSections } from '../../../types/report.d';
import { useGetAllPowerbiQuery } from '../../../features/powerbi/powerbiApi.slice';
import { setErrorMessage, setSuccessMessage } from '../../../features/layout/layout.slice';
import { IPowerbiReports } from '../../../types/powerbi.d';

interface IClientReports {
  [key: string]: IReport[];
}

interface IPowerbiClientReports extends IPowerbiReports {
  clientReports: IClientReports;
}

function ReportSettings() {
  const dispatch = useDispatch();
  const [updateReport] = useUpdateReportMutation();
  const [newRole, setNewRole] = useState<string>('');
  const [addingRole, setAddingRole] = useState<boolean>(false);
  const { data: powerbis } = useGetAllPowerbiQuery(true);
  const [editingReport, setEditingReport] = useState<IReport | null>(null);
  const [accordionActiveKey, setAccordionActiveKey] = useState<string | null>(null);
  const [workspaceReports, setWorkspaceReports] = useState<IPowerbiClientReports[] | null>(null);

  const isReportUnconfigured = (report: IReport): boolean => {
    return !report.clientId || (report.hasRLS && !report.rlsRoles.length);
  };

  const resetRoleAdding = () => {
    setNewRole('');
    setAddingRole(false);
  };

  const handleUpdateRoles = (role: string, isNewRole: boolean) => {
    if (!editingReport || !role) return;
    setEditingReport({
      ...editingReport,
      rlsRoles: isNewRole
        ? [...editingReport.rlsRoles, role]
        : editingReport.rlsRoles.filter((r) => r !== role),
    });
    resetRoleAdding();
  };

  const handleSchoolYearSectionChange = (section: SchoolYearSections, add: boolean) => {
    if (!editingReport) return;
    setEditingReport({
      ...editingReport,
      schoolYearSections: add
        ? [...editingReport.schoolYearSections, section]
        : editingReport.schoolYearSections.filter((s) => s !== section),
    });
  };

  const onUpdateReport = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!editingReport) return;
    const { id, clientId, rlsRoles, forGuardians, schoolYearSections } = editingReport;
    updateReport({ id, clientId, rlsRoles, forGuardians, schoolYearSections })
      .unwrap()
      .then(() => {
        dispatch(setSuccessMessage(`Report ${editingReport.name} successfully updated`));
      })
      .catch(() => dispatch(setErrorMessage(`Report ${editingReport.name} update failed`)));
  };

  const accordionToggle = (activeKey: string | null) => {
    if (activeKey === accordionActiveKey) {
      setAccordionActiveKey(null);
    } else {
      setAccordionActiveKey(activeKey);
    }
  };

  useEffect(() => {
    if (!accordionActiveKey) {
      setEditingReport(null);
    }
  }, [accordionActiveKey]);

  useEffect(() => {
    resetRoleAdding();
  }, [editingReport]);

  useEffect(() => {
    if (powerbis)
      setWorkspaceReports(
        powerbis.map((pbi) => {
          const clientReports: IClientReports = { 'No Client': [] };
          pbi.clients.forEach((c) => {
            clientReports[c.name] = [];
          });
          pbi.reports.forEach((r) => {
            const client = pbi.clients.find((c) => c.id === r.clientId);
            clientReports[client?.name ?? 'No Client'].push(r);
          });
          return {
            ...pbi,
            clientReports,
          };
        })
      );
    if (powerbis?.length && editingReport) {
      setEditingReport(
        powerbis
          .find((powerbi) => powerbi.id === accordionActiveKey)
          ?.reports.find((report) => report.id === editingReport.id) ?? null
      );
    }
  }, [powerbis]);

  return (
    <div id="reportSettings">
      <span className="report-title"> Reports </span>
      <div className="report-content">
        <div className="left-container">
          <Accordion>
            {workspaceReports?.map((powerbi) => (
              <Accordion.Item key={powerbi.id} eventKey={powerbi.id}>
                <Accordion.Header
                  onClick={() => accordionToggle(powerbi.id)}
                  className="powerbi-name"
                >
                  {powerbi.workspaceName?.toUpperCase()}
                </Accordion.Header>
                <Accordion.Body>
                  <Accordion>
                    {Object.entries(powerbi.clientReports).map(([client, reports]) => (
                      <Accordion.Item key={client} eventKey={client}>
                        <Accordion.Header>{client}</Accordion.Header>
                        <Accordion.Body>
                          {reports.map((report) => (
                            <Button
                              className="report-select-btn"
                              key={report.id}
                              onClick={() => setEditingReport(report)}
                              active={editingReport?.id === report.id}
                            >
                              {isReportUnconfigured(report) && (
                                <ExclamationTriangleFill size={16} className="warning" />
                              )}
                              <span className="report-select-btn-name">{report.name}</span>
                              <ChevronRight />
                            </Button>
                          ))}
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
        <div className="right-container">
          {!editingReport && (
            <div className="d-flex justify-content-center align-items-center my-3 fw-bold">
              Select a report to start editing the settings.
            </div>
          )}
          {editingReport && (
            <Form className="report-settings-container" onSubmit={onUpdateReport}>
              <Form.Group className="report-form-row">
                <Form.Label className="form-label"> Client </Form.Label>
                <Form.Select
                  value={editingReport.clientId ?? ''}
                  onChange={({ target }) =>
                    setEditingReport({ ...editingReport, clientId: target.value || null })
                  }
                >
                  <option value=""> - </option>
                  {powerbis &&
                    powerbis
                      .find((powerbi) => powerbi.id === accordionActiveKey)
                      ?.clients.map((client) => (
                        <option key={client.id} value={client.id}>
                          {client.name}
                        </option>
                      ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="report-form-row" id="report-guardian-switch">
                <Form.Label className="form-label"> For guardians </Form.Label>
                <Form.Switch
                  checked={editingReport.forGuardians}
                  onChange={({ target }) =>
                    setEditingReport({ ...editingReport, forGuardians: target.checked })
                  }
                />
              </Form.Group>
              {editingReport.forGuardians && (
                <Form.Group className="report-form-row">
                  <Form.Label className="form-label"> School Year Sections </Form.Label>
                  <Form.Check className="report-guardian-years">
                    <div className="report-guardian-year">
                      <Form.Check.Label> Primary </Form.Check.Label>
                      <Form.Check.Input
                        onChange={({ target }) =>
                          handleSchoolYearSectionChange(SchoolYearSections.PRIMARY, target.checked)
                        }
                        checked={editingReport.schoolYearSections.includes(
                          SchoolYearSections.PRIMARY
                        )}
                      />
                    </div>
                    <div className="report-guardian-year">
                      <Form.Check.Label> Middle </Form.Check.Label>
                      <Form.Check.Input
                        onChange={({ target }) =>
                          handleSchoolYearSectionChange(SchoolYearSections.MIDDLE, target.checked)
                        }
                        checked={editingReport.schoolYearSections.includes(
                          SchoolYearSections.MIDDLE
                        )}
                      />
                    </div>
                    <div className="report-guardian-year">
                      <Form.Check.Label> Secondary </Form.Check.Label>
                      <Form.Check.Input
                        onChange={({ target }) =>
                          handleSchoolYearSectionChange(
                            SchoolYearSections.SECONDARY,
                            target.checked
                          )
                        }
                        checked={editingReport.schoolYearSections.includes(
                          SchoolYearSections.SECONDARY
                        )}
                      />
                    </div>
                  </Form.Check>
                </Form.Group>
              )}
              {editingReport.hasRLS && (
                <Form.Group>
                  <Form.Label className="form-label"> Roles </Form.Label>
                  {editingReport?.rlsRoles.map((role) => (
                    <div key={`${editingReport.id}-${role}`} className="report-roles">
                      <div className="report-role"> {role} </div>
                      <Button onClick={() => handleUpdateRoles(role, false)}>
                        <Trash />
                      </Button>
                    </div>
                  ))}
                  {addingRole && (
                    <div className="report-roles">
                      <input
                        className="add-role-input report-role"
                        placeholder="Role name..."
                        value={newRole}
                        onChange={(e) => setNewRole(e.target.value)}
                        required
                      />
                      <div className="d-flex">
                        <Button className="mx-1" onClick={() => handleUpdateRoles(newRole, true)}>
                          <Save />
                        </Button>
                        <Button onClick={resetRoleAdding}>
                          <Trash />
                        </Button>
                      </div>
                    </div>
                  )}
                  {!addingRole && editingReport && (
                    <Button className="add-role-btn" onClick={() => setAddingRole(true)}>
                      Add new role
                    </Button>
                  )}
                </Form.Group>
              )}
              <Button className="form-button" type="submit">
                SAVE
              </Button>
            </Form>
          )}
        </div>
      </div>
    </div>
  );
}

export default ReportSettings;

import React from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setErrorMessage, setSuccessMessage } from '../../../features/layout/layout.slice';
import IUser, { UserRole } from '../../../types/user.d';
import { useUpdateUserMutation } from '../../../features/user/userApi.slice';
import { isSupport } from '../../../utils/authorization.utils';
import { useAppSelector } from '../../../features/hooks';
import { selectIsGuardianSession } from '../../../features/auth/auth.slice';

interface IEditUserFormProps {
  selectedUser: IUser | null; // User that is being edited
  readOnly: boolean;
}

function EditUser(props: IEditUserFormProps): JSX.Element {
  const { selectedUser, readOnly } = props;
  const dispatch = useDispatch();
  const [updateUser] = useUpdateUserMutation();
  const isGuardianSession = useAppSelector(selectIsGuardianSession);

  const onRoleChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    if (!selectedUser) return;
    updateUser({
      id: selectedUser.id,
      role: value as UserRole,
    })
      .unwrap()
      .then(() => dispatch(setSuccessMessage('User role updated succesfully')))
      .catch(() => dispatch(setErrorMessage('Unable to update user')));
  };

  return (
    <div className="edit-user-container">
      {selectedUser && (
        <Form id="editUserForm">
          <div className="edit-header">
            <p className="edit-header-title">User email</p>
            <span className="edit-header-value">{selectedUser?.email}</span>
          </div>
          <div className="edit-header">
            <p className="edit-header-title">User role</p>
            <div className="user-role-radio">
              {Object.values(UserRole)
                .filter((r) => r !== UserRole.GLOBAL_ADMIN)
                .map((role: UserRole) => (
                  <Form.Check
                    inline
                    value={role}
                    key={role}
                    type="radio"
                    label={role.toUpperCase()}
                    onChange={onRoleChange}
                    checked={selectedUser.role === role}
                    readOnly={readOnly}
                  />
                ))}
            </div>
          </div>
          {isSupport(selectedUser.role, isGuardianSession) && (
            <span id="support-role-span">This role grants read-only access to the settings.</span>
          )}
        </Form>
      )}
    </div>
  );
}

export default EditUser;

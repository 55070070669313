import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { IUserSections } from '../../types/user.d';
import { IClientPowerbi } from '../../types/client.d';

interface IUserState {
  currentUser: IUserSections | null;
  currentUserClient: IClientPowerbi | null;
}

const initialState: IUserState = {
  currentUser: null,
  currentUserClient: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveCurrentUser: (state, action: PayloadAction<IUserSections | null>) => {
      state.currentUser = action.payload;
      state.currentUserClient = action.payload?.clients[0] ?? null;
    },
    saveCurrentUserClient: (state, action: PayloadAction<IClientPowerbi>) => {
      state.currentUserClient = action.payload;
    },
  },
});

export const { saveCurrentUser, saveCurrentUserClient } = userSlice.actions;

export const selectCurrentUser = (state: RootState) => state.user.currentUser;
export const selectCurrentUserClient = (state: RootState) => state.user.currentUserClient;

export default userSlice.reducer;

import { Configuration, PopupRequest, RedirectRequest } from '@azure/msal-browser';

const { REACT_APP_REDIRECT_URI, REACT_APP_CLIENT_ID } = process.env;

const clientCode = window.location.host.split('.')[0];
const redirectUri = `https://${clientCode}.${REACT_APP_REDIRECT_URI}`;

export const msalConfig: Configuration = {
  auth: {
    clientId: REACT_APP_CLIENT_ID ?? '',
    authority: 'https://login.microsoftonline.com/common',
    redirectUri,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

export const msGraphRequest: PopupRequest = {
  scopes: ['User.Read'],
};

export const redirectRequest: RedirectRequest = {
  scopes: ['User.Read'],
  redirectUri,
  redirectStartPage: redirectUri,
};

import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Accordion, Button, ModalProps } from 'react-bootstrap';
import { ArrowClockwise, CircleFill } from 'react-bootstrap-icons';
import {
  ActionateResourceAction,
  useLazyActionateResourceQuery,
  useLazyGetAllClientsResourcesQuery,
} from '../../../features/client/clientApi.slice';
import Loader from '../../../components/Loader/Loader';
import Confirmation from '../../../components/Confirmation/Confirmation';
import { ResourceStatus, IClientResources, ResourceTypes } from '../../../types/client.d';
import { setErrorMessage, setSuccessMessage } from '../../../features/layout/layout.slice';

const statusMap = {
  [ResourceStatus.ERROR]: ['error', 'dw-error'],
  [ResourceStatus.READY]: ['stop', 'dw-started'],
  [ResourceStatus.STOPPED]: ['start', 'dw-stopped'],
  [ResourceStatus.ON_PREM]: ['on-prem', 'dw-onprem'],
  [ResourceStatus.STOPPING]: ['loading', 'dw-updating'],
  [ResourceStatus.STARTING]: ['loading', 'dw-updating'],
  [ResourceStatus.UPDATING]: ['loading', 'dw-updating'],
};

const canActionateResource = (status: ResourceStatus): boolean =>
  [ResourceStatus.READY, ResourceStatus.STOPPED].includes(status);

function ClientsResources() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [actionateResource] = useLazyActionateResourceQuery();
  const [clients, setClients] = useState<IClientResources[]>();
  const [getAllClientsResources] = useLazyGetAllClientsResourcesQuery();
  const [accordionActiveKey, setAccordionActiveKey] = useState<string | null>(null);
  const [isActionatingResource, setIsActionatingResource] = useState<boolean>(false);
  const [clientResource, setClientResource] = useState<IClientResources | null>(null);
  const [actionatedResource, setActionatedResource] = useState<ResourceTypes | null>(null);

  const actionateResourceModalProps: ModalProps = {
    show: isActionatingResource,
    onHide: () => {
      setClientResource(null);
      setActionatedResource(null);
      setIsActionatingResource(false);
    },
    centered: true,
  };

  const onGetClientsDataWarehouses = () => {
    setIsLoading(true);
    getAllClientsResources()
      .unwrap()
      .then((data) => setClients(data))
      .finally(() => {
        setIsLoading(false);
        setAccordionActiveKey(null);
      });
  };

  const onActionateDataWarehouse = async (
    client: IClientResources,
    checkStatus = false,
    resource = actionatedResource
  ) => {
    if (!client || !resource) return;
    let action: ActionateResourceAction;
    let resourceProperty: 'dataWarehouseStatus' | 'pipelineVMStatus' | 'airbyteVMStatus';
    if (resource === ResourceTypes.DATA_WAREHOUSE) {
      resourceProperty = 'dataWarehouseStatus';
    } else if (resource === ResourceTypes.PIPELINE_VM) {
      resourceProperty = 'pipelineVMStatus';
    } else {
      resourceProperty = 'airbyteVMStatus';
    }
    const currentStatus = client[resourceProperty];
    if (!checkStatus && !canActionateResource(currentStatus)) return;
    if (!checkStatus) {
      action =
        currentStatus === ResourceStatus.READY
          ? ActionateResourceAction.STOP
          : ActionateResourceAction.START;
    } else action = ActionateResourceAction.STATUS;
    await actionateResource({ code: client.code, action, resource })
      .unwrap()
      .then(({ status }) => {
        if (!clients) return;
        const updatedClients: IClientResources[] = clients.map((c) => {
          if (c.id !== client.id) return c;
          return {
            ...c,
            [resourceProperty]: status,
          };
        });
        setClients(updatedClients);
        setActionatedResource(null);
        dispatch(setSuccessMessage(`${resource} status updated`));
      })
      .catch(() => dispatch(setErrorMessage(`Error while trying to ${action} the ${resource}`)));
  };

  const accordionToggle = (activeKey: string | null) => {
    if (activeKey === accordionActiveKey) {
      setAccordionActiveKey(null);
    } else {
      setAccordionActiveKey(activeKey);
    }
  };

  useEffect(() => {
    onGetClientsDataWarehouses();
  }, []);

  return (
    <div id="clientResources">
      <span className="resources-title">Resources</span>
      <div className="title-container">
        <Button className="dw-refresh-btn" variant="secondary" onClick={onGetClientsDataWarehouses}>
          REFRESH
        </Button>
        <div className="title-status-labels">
          <span>Data Warehouse</span>
          <span>VM Pipeline</span>
          <span>VM Airbyte</span>
        </div>
      </div>
      {isLoading && <Loader message="Loading Data Warehouses..." className="dw-loader" />}
      {!isLoading && (
        <Accordion>
          {clients?.map((client) => (
            <Accordion.Item className="client-dw" key={client.id} eventKey={client.id}>
              <Accordion.Header
                onClick={() => accordionToggle(client.id)}
                className="dw-acc-header"
              >
                <div className="client-dw-header">
                  <div className="client-dw-title">
                    <span className="client-dw-name">{client.name}</span>
                    <span className="client-dw-code">#{client.code}</span>
                  </div>
                  {client.id !== accordionActiveKey && (
                    <div className="client-header-status">
                      <div className="dw-status-container">
                        <CircleFill
                          size={16}
                          className="dw-status"
                          id={statusMap[client.dataWarehouseStatus][1]}
                        />
                        <span>{client.dataWarehouseStatus.toUpperCase()}</span>
                      </div>
                      <div className="dw-status-container">
                        <CircleFill
                          size={16}
                          className="dw-status"
                          id={statusMap[client.pipelineVMStatus][1]}
                        />
                        <span>{client.pipelineVMStatus.toUpperCase()}</span>
                      </div>
                      <div className="dw-status-container">
                        <CircleFill
                          size={16}
                          className="dw-status"
                          id={statusMap[client.airbyteVMStatus][1]}
                        />
                        <span>{client.airbyteVMStatus.toUpperCase()}</span>
                      </div>
                    </div>
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body className="dw-acc-body">
                <div className="dw-body-links">
                  <a
                    className="dw-links"
                    href={`https://edubikestra.blob.core.windows.net/${client.code}/kestra_logs.log`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Kestra
                  </a>
                  <a
                    className="dw-links"
                    href={`https://edubidbtdocs.blob.core.windows.net/${client.code}/static_index.html#!/overview`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    DBT Docs
                  </a>
                  <a
                    className="dw-links"
                    href={`https://edubielementary.blob.core.windows.net/${client.code}/index.html#/report/dashboard`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Elementary
                  </a>
                  <a
                    className="dw-links"
                    href={`https://edubialerts.blob.core.windows.net/${client.code}/alert_logs.csv`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Alerts
                  </a>
                </div>
                <div className="dw-body-statuses">
                  <div className="dw-body-status">
                    <span className="dw-resource-title">Data Warehouse</span>
                    <div className="dw-status-buttons">
                      <div className="dw-status-container">
                        <CircleFill
                          size={16}
                          className="dw-status"
                          id={statusMap[client.dataWarehouseStatus][1]}
                        />
                        <span>{client.dataWarehouseStatus.toUpperCase()}</span>
                      </div>
                      <Button
                        id="dw-action-btn"
                        onClick={() => {
                          setClientResource(client);
                          setActionatedResource(ResourceTypes.DATA_WAREHOUSE);
                          setIsActionatingResource(true);
                        }}
                        disabled={!canActionateResource(client.dataWarehouseStatus)}
                        variant="secondary"
                      >
                        {statusMap[client.dataWarehouseStatus][0].toUpperCase()}
                      </Button>
                      <Button
                        id="dw-refresh-btn"
                        onClick={() =>
                          onActionateDataWarehouse(client, true, ResourceTypes.DATA_WAREHOUSE)
                        }
                      >
                        <ArrowClockwise size={16} />
                      </Button>
                    </div>
                  </div>
                  <div className="dw-body-status">
                    <span className="dw-resource-title">Pipeline VM</span>
                    <div className="dw-status-buttons">
                      <div className="dw-status-container">
                        <CircleFill
                          size={16}
                          className="dw-status"
                          id={statusMap[client.pipelineVMStatus][1]}
                        />
                        <span>{client.pipelineVMStatus.toUpperCase()}</span>
                      </div>
                      <Button
                        id="dw-action-btn"
                        onClick={() => {
                          setClientResource(client);
                          setActionatedResource(ResourceTypes.PIPELINE_VM);
                          setIsActionatingResource(true);
                        }}
                        disabled={!canActionateResource(client.pipelineVMStatus)}
                        variant="secondary"
                      >
                        {statusMap[client.pipelineVMStatus][0].toUpperCase()}
                      </Button>
                      <Button
                        id="dw-refresh-btn"
                        onClick={() =>
                          onActionateDataWarehouse(client, true, ResourceTypes.PIPELINE_VM)
                        }
                      >
                        <ArrowClockwise size={16} />
                      </Button>
                    </div>
                  </div>
                  <div className="dw-body-status">
                    <span className="dw-resource-title">Airbyte VM</span>
                    <div className="dw-status-buttons">
                      <div className="dw-status-container">
                        <CircleFill
                          size={16}
                          className="dw-status"
                          id={statusMap[client.airbyteVMStatus][1]}
                        />
                        <span>{client.airbyteVMStatus.toUpperCase()}</span>
                      </div>
                      <Button
                        id="dw-action-btn"
                        onClick={() => {
                          setClientResource(client);
                          setActionatedResource(ResourceTypes.AIRBYTE_VM);
                          setIsActionatingResource(true);
                        }}
                        disabled={!canActionateResource(client.airbyteVMStatus)}
                        variant="secondary"
                      >
                        {statusMap[client.airbyteVMStatus][0].toUpperCase()}
                      </Button>
                      <Button
                        id="dw-refresh-btn"
                        onClick={() =>
                          onActionateDataWarehouse(client, true, ResourceTypes.AIRBYTE_VM)
                        }
                        disabled={client.airbyteVMStatus === ResourceStatus.ON_PREM}
                      >
                        <ArrowClockwise size={16} />
                      </Button>
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      )}
      <Confirmation
        title={`Actionate ${actionatedResource}`}
        body={`Are you sure you want to start/stop the ${clientResource?.name}'s ${actionatedResource}?`}
        handleConfirm={async () => {
          if (!clientResource) return;
          setIsActionatingResource(true);
          await onActionateDataWarehouse(clientResource);
          setIsActionatingResource(false);
        }}
        modalProps={actionateResourceModalProps}
      />
    </div>
  );
}

export default ClientsResources;
